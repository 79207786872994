import axios from 'axios';
import { WP_ROOT, IS_PROD } from './api-config';

const api = `${WP_ROOT}/wp-json/menus/v1/menus`;
// const api = `https://autocom.mx/wp-json/menus/v1/menus`;

const username = 'autocom';
const password = 'BajaCalifornia';
const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

const headers = {
  Authorization: IS_PROD ? null : `Basic ${token}`,
};

const formatItems = (items) => (items ? items.map((item) => ({
  title: item.title.replace(/<[^>]*>?/gm, '').trim(),
  url: item.url,
})) : []);
const getLinks = (links) => axios.get(`${api}/${links}`, { params: {}, headers }).then((res) => formatItems(res.data.items));
const getFooter = (id) => getLinks(`footer${id}`);

export const getHeaderLinks = () => getLinks('header-links');
export const getMainMenuLinks = () => getLinks('main-menu');
export const getSidebarLinks = () => getLinks('sidebar-additional-links');
export const getCopyrightLinks = () => getLinks('copyright');
// export const getSocialLinks = () => getLinks('social');

export async function getFooterLinks() {
  const footer1 = await getFooter(1);
  const footer2 = await getFooter(2);
  const footer3 = await getFooter(3);
  const footer4 = await getFooter(4);
  const footer5 = await getFooter(5);
  const footer6 = await getFooter(6);
  return {
    footer1, footer2, footer3, footer4, footer5, footer6,
  };
}
