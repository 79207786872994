import { combineReducers } from 'redux';

import user from './user';
import links from './links';
import dealers from './dealers';
import vehicles from './vehicles';
import similar from './similar';
import pagination from './pagination';
import vehicle from './vehicle';
import compare from './compare';
import filters from './filters';
import loading from './shared';
import redirect from './redirect';
import reloadInventory from './reloadInventory';
import loadingFilters from './loadingFilters';
import selectedFilters from './selectedFilters';
import common from './common';
import leads from './leads';

export default combineReducers({
  links,
  filters,
  selectedFilters,
  pagination,
  vehicles,
  vehicle,
  compare,
  loading,
  dealers,
  loadingFilters,
  reloadInventory,
  similar,
  user,
  redirect,
  common,
  leads,
});
