export const ADD_LEAD = 'ADD_LEAD';
export const CLEAN_LEADS = 'CLEAN_LEADS';

export function addLead(key, value) {
  return {
    type: ADD_LEAD,
    lead_key: key,
    lead_value: value,
  };
}

export function cleanLeads() {
  return {
    type: CLEAN_LEADS,
  };
}
