export const RECEIVE_FILTER = 'RECEIVE_FILTER';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTERS = 'SET_FILTERS';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER'

export function receiveSelectedFilters(filters) {
  return {
    type: RECEIVE_FILTER,
    filters,
  };
}

export function resetSelectedFilters() {
  return {
    type: RESET_FILTER,
  };
}


export const setFilter = (key, value) => ({
  type: SET_FILTER,
  key,
  value
});

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  filters
});

export const toggleFilter = (key, value) => ({
  type: TOGGLE_FILTER,
  key,
  value
});

export const addFilter = (key, value) => ({
  type: TOGGLE_FILTER,
  key,
  value
});

export const removeFilter = (key, value) => ({
    type: REMOVE_FILTER,
    key,
    value
});
