import React, { useState, memo } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import withStyles from '@material-ui/core/styles/withStyles'
import { useDispatch } from 'react-redux'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Lazy, Autoplay } from 'swiper'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Swiper from 'new/components/Swiper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import styles from './styles'
import { useHistory, Link } from 'react-router-dom'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy, Autoplay])

const SWIPER_ITEMS = [
  { id: 1, textId: 'ONLY_PROVIDE_BASIC_INFORMATION' },
  { id: 2, textId: 'RECEIVE_INSTANT_OFFERS' },
  { id: 3, textId: 'CHOSE_YOUR_OFFER' }
]

const Section1 = (props) => {
  const { classes } = props
  const { formatMessage: f } = useIntl()
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const dispatch = useDispatch()
  const history = useHistory()

  const goToForm = () => {
    history.push("/vende-tu-auto/cotiza/paso1")
  }

  const handleScroll = () => {
    const element = document.getElementById('section2');
    const headerOffset = 100;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    })
  }

  return (
    <Container className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={4}
        >
          <Typography
            className={classes.title}
            variant="h1"
            color="primary"
          >
            <FormattedMessage id="WE_CHANGE_BUY_OR_SELL_FOR_YOU"/>
          </Typography>

          <Typography
            className={classes.subtitle}
            variant="h4"
          >
            <FormattedMessage id="DO_IT_WITH_ONE_CLICK"/>
          </Typography>

          <Button
            component={Link}
            id="quote-your-car-btn-section-1"
            className={classes.quoteButton}
            variant="contained"
            color="primary"
            to="/vende-tu-auto/cotiza/paso1"
            fullWidth
          >
            <FormattedMessage id="QUOTE_YOUR_CAR" />
          </Button>

          <Hidden smDown>
            <Button fullWidth>
              <FormattedMessage id="RECOMMEND_TO_YOUR_FRIENDS"/>
            </Button>
          </Hidden>
        </Grid>

        <Hidden mdDown>
          <Grid item lg={2} />
        </Hidden>

        <Grid
          className={classes.right}
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={4}
        >
          <Hidden xsDown>
            <img
              className={classes.swiperBg}
              src={`/clikauto/img/home-slider-${activeSlideIndex + 1}.png`}
            />
          </Hidden>

          <Hidden smUp>
            <img
              className={classes.swiperBg}
              src={`/clikauto/img/m-home-slider-${activeSlideIndex + 1}.png`}
            />
          </Hidden>

          <Swiper
            options={{
              autoplay: {
                delay: 5000,
              },
            }}
            component={Paper}
            className={activeSlideIndex === 1 ? classes.swiper2 : classes.swiper}
            items={SWIPER_ITEMS}
            onActiveSlideChange={setActiveSlideIndex}
            showNavigation
            showPagination
            showActiveIndex
            classes={{
              activeIndex: classes.swiperActiveIndex,
              pagination: classes.swiperPagination,
              navigation: classes.swiperNavigation
            }}
            renderItem={item => (
              <Typography variant="h4">
                <FormattedMessage id={item.textId} />
              </Typography>
            )}
          />
        </Grid>
      </Grid>

      <Box
        flexDirection="column"
        alignItems="center"
        display={{ xs: 'none', sm: 'flex' }}
      >
        <ArrowDownwardIcon />
        <MuiLink color="inherit" onClick={handleScroll}>
          <FormattedMessage id="KNOW_MORE" />
        </MuiLink>
      </Box>
    </Container>
  )
}

export default memo(withStyles(styles)(Section1))
