const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },

  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: 18,
    textAlign: 'center',
  },

  summaryContainer: {
    maxWidth: 630,
    margin: '0 auto',
    backgroundColor: '#F9F9F9',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },

  summaryTitle: {
    marginBottom: theme.spacing(3),
  },

  summaryInner: {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#F2F2F2',
    padding: theme.spacing(3),
  },

  summary: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.black,
    flex: 1,
    borderRadius: theme.shape.borderRadius * 2,
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },

  bottomButtons: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
  },

  divider: {

  },

  whatsapp: {
    '& h3': {
      fontSize: '20px!important',
    },
  },

  name: {
    '& h3': {
      fontSize: '20px!important',
    },
  },

  office: {
    '& h3': {
      fontSize: '20px!important',
    },
  },

  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& path': {
      fill: '#07AE91',
    },
  },
});

export default styles;
