import * as API from "../../lib/api";
import { updateLoadingFilters } from "./shared";

export const RECEIVE_FILTERS = "RECEIVE_FILTERS";
export const GET_MODELS = "GET_MODELS";

export function receiveFilters(filters) {
  return {
    type: RECEIVE_FILTERS,
    filters
  };
}
export function getModels(models) {
  return {
    type: GET_MODELS,
    models
  };
}

export function handleInitialFilters(params) {
  return dispatch => {
    dispatch(updateLoadingFilters(true));
    API.getFilters(params)
      .then(data => {
        dispatch(receiveFilters(data));
      })
      .then(() => dispatch(updateLoadingFilters(false)));
  };
}

export function handleGetModels(maker, params = {}) {
  return dispatch => {
    // dispatch(updateLoadingModels(true));
    API.getModels(maker, params).then(data => {
      dispatch(getModels(data));
    });
    // .then(() => dispatch(updateLoadingModels(false)));
  };
}
