import React, { useEffect, useState, memo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import styles from './styles'

const RequestDialog = ({ open, classes, title, subtitles=[], icon }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => setActiveIndex(prev => (prev + 1) % subtitles.length), 1000)

    return () => {
      clearInterval(interval)
    }
  }, [subtitles])

  return (
    <Dialog
      open={open}
      fullScreen
      PaperProps={{
        className: classes.paper
      }}
    >
      <Typography
        variant="h3"
        className={classes.line1}
      >
        {title}
      </Typography>

      <Box m={2}>
        {icon}
      </Box>

      <Typography
        variant="h3"
        className={classes.line2}
      >
        {subtitles[activeIndex]}
      </Typography>
    </Dialog>
  )
}


export default memo(withStyles(styles)(RequestDialog))
