// TODO: ALl this data should be static.
//  And saved in some place where normal people can edit it.
//  e.g an admin panel or a wordpress applications
// The idea is this data to be retrieved at build time in order to generate static data

export const mainMenu = [
  {
    id: 100,
    url: 'https://clikauto.com/vende-tu-auto',
    title: 'Vende tu auto',
    items: [
      {
        id: 101,
        url: '#',
        title: 'Cambia de auto',
      },
      {
        id: 102,
        url: '#',
        title: 'Compramos tu auto',
      },
      {
        id: 103,
        url: '#',
        title: 'Vendemos tu auto',
      },
    ],
  },
  {
    id: 200,
    url: 'https://clikauto.com/comprar-autos',
    title: 'Compra un auto',
  },
  {
    id: 300,
    url: 'https://clikauto.com/financiamiento/',
    title: 'Financiamiento',
    items: [
      {
        id: 301,
        url: '#',
        title: 'Pre-Califica',
      },
      // {
      //   id: 302,
      //   url: '#',
      //   title: 'Renting'
      // },
      // {
      //   id: 303,
      //   url: '#',
      //   title: 'Leasing'
      // }

    ],
  },
  // {
  //   id: 400,
  //   url: 'https://clikauto.com/contacto/',
  //   title: 'Contacto',
  //   items: []
  // },
  // {
  //   id: 400,
  //   url: '#',
  //   title: 'Acerca de ClikAuto',
  //   items: [
  //     // {
  //     //   id: 401,
  //     //   url: '#',
  //     //   title: 'Nuestra historia'
  //     // },
  //     // {
  //     //   id: 402,
  //     //   url: '#',
  //     //   title: 'Preguntas frequentes'
  //     // },
  //     // {
  //     //   id: 403,
  //     //   url: '#',
  //     //   title: 'El equipo'
  //     // },
  //     // {
  //     //   id: 404,
  //     //   url: '#',
  //     //   title: 'Unete al equipo'
  //     // },
  //   ]
  // },
  // {
  //   id: 500,
  //   url: 'https://clikauto.com/contactanos/',
  //   title: 'Contacto'
  // },
  // {
  //   id: 600,
  //   url: 'https://app.clikauto.com/login',
  //   title: 'Mi ClikAuto'
  // }
];

export const footerMenu = [
  {
    id: 99,
    url: '//clikauto.com',
    title: 'ClikAuto',
  },
  {
    id: 100,
    url: 'https://clikauto.com/vende-tu-auto',
    title: 'Vende un auto',
    items: [
      {
        id: 101,
        url: '#',
        title: 'Cambia de auto',
      },
      {
        id: 102,
        url: '#',
        title: 'Compramos tu auto',
      },
      {
        id: 103,
        url: '#',
        title: 'Vendemos tu auto',
      },
    ],
  },
  {
    id: 200,
    url: '//app.clikauto.com',
    title: 'Compra un auto',
  },
  {
    id: 300,
    url: 'https://clikauto.com/financiamiento/',
    title: 'Financiamiento',
    items: [
      {
        id: 301,
        url: '#',
        title: 'Pre-Califica',
      },
      // {
      //   id: 302,
      //   url: '#',
      //   title: 'Renting'
      // },
      // {
      //   id: 303,
      //   url: '#',
      //   title: 'Leasing'
      // }

    ],
  },
  // {
  //   id: 400,
  //   url: '#',
  //   title: 'Acerca de ClikAuto',
  //   items: [
  //     // {
  //     //   id: 401,
  //     //   url: '#',
  //     //   title: 'Nuestra historia'
  //     // },
  //     // {
  //     //   id: 402,
  //     //   url: '#',
  //     //   title: 'Preguntas frequentes'
  //     // },
  //     // {
  //     //   id: 403,
  //     //   url: '#',
  //     //   title: 'El equipo'
  //     // },
  //     // {
  //     //   id: 404,
  //     //   url: '#',
  //     //   title: 'Unete al equipo'
  //     // },
  //   ]
  // },
  // {
  //   id: 600,
  //   url: 'https://app.clikauto.com/login',
  //   title: 'Mi ClikAuto'
  // }
];

export const faqs = [
  {
    id: 1,
    question: '¿Cómo puedo vender mi auto en ClikAuto?',
    answer: `
      Puedes vender como tú quieras. Sólo tienes que ingresar los datos de tu auto y te brindamos 3 opciones; 1- Oferta inmediata, 2-  Consignación ó 3- Publicar tu auto y venderlo tú mismo.
      </br>
      <br />
      Si escoges oferta inmediata o consignación, uno de nuestros ejecutivos te contactará para agendar una “Inspección Express” en uno de nuestros puntos autorizados.
      </br> 
      <br />
      Con el resultado de la inspección te confirmaremos nuestras ofertas disponibles para tu auto; vender tu auto con ClikAuto es muy fácil, rápido, sencillo y seguro.
    `,
  },

  {
    id: 2,
    question: '¿Es seguro vender mi auto con ustedes?',
    answer: `
      Es completamente seguro y transparente. Nunca te solicitaremos documentación original de tu auto sin darte una oferta firme, y nos aseguramos de protegerte y proteger tus datos en todo momento.
      <br />
      Cuando aceptas la formal tu auto, nos entregas tu documentación original completa y  procedemos a la firma de compra-venta de contrato.
      <br />
      Para ese momento tú ya tienes formalidad de la compra y ahora sólo esperas el pago (depósito bancario en tu cuenta) por tu auto en alrededor  de 48hrs.
    `,
  },

  {
    id: 3,
    question: '¿Puedo vender mi auto desde cualquier lugar del país?',
    answer: `
    Puedes vender a ClikAuto en modo venta inmediata y consignación desde CDMX y Area Metropolitana, Queretaro (QRO) y Morelia (MICH) 
    <br />
    <br />
    Si te encuentras en otra ciudad, puedes publicar gratis tu auto en nuestro marketplace para venderlo por tu cuenta.
    `,
  },

  {
    id: 4,
    question: '¿Qué documentos debo presentar para vender a ClikAuto?',
    answer: `
    <strong>Eres único dueño:</strong>
    <br />
    <ul>
      <li><strong>Factura origen</strong> (impresa y digital pdf/xml*).</li>
      <li><strong>Tarjeta de circulación </strong>(vigente).</li>
      <li><strong>Comprobante verificación para autos con placas de Edo. Mex., Cdmx., Morelos </strong>(vigente).</li>
      <li><strong>Comprobante de domicilio</strong> (Cfe, Agua o Telmex no mayor a 3 meses).</li>
      <li><strong>Tenencias pagadas </strong>(originales).</li>
      <li><strong>Baja de placas </strong> (ya no es necesario tarjeta de circulación).</li>
      <li><strong>Duplicado de llaves originales.</strong></li>
      <li><strong>Carnet/libro de servicios.</strong></li>
      <li><strong>Identificación oficial </strong>(INE/IFE, Cédula profesional o Pasaporte vigente).</li>
      <li><strong>Comprobante de domicilio </strong>(CFE, Agua o Telmex menor a 3 meses).</li>
      <li><strong>Comprobante de domicilio</strong> (Cfe, Agua o Telmex no mayor a 3 meses).</li>
      <li><strong>Carátula de estado de cuenta bancario</strong> (cuenta en la que se realiza el pago por transferencia, debe ser a nombre del actual propietario del auto o a nombre del titular del auto).</li>
      <li><strong>Cédula de identificación fiscal </strong>(acreditación de actividad económica ante el SAT, en caso de no contar con ella nosotros la podemos conseguir). </li>
    </ul>
    
    <br />
    <strong>No eres único dueño: </strong>
    <br />
    
    <ul>
    <li><strong>Factura de origen endosada </strong>(impresa endoso dueños anteriores y digital pdf/xml*).</li>
    <li><strong>Copia factura de origen </strong> (si compraste a persona moral o física con actividad empresarial y tienes refacturación).</li>
    <li><strong>Comprobante de compra-venta </strong>(refactura y/o endoso).</li>
    <li><strong>Tarjeta de circulación </strong>(vigente).</li>
    <li><strong>Comprobante verificación para autos con placas de Edo. Mex., Cdmx., Morelos </strong> (vigente).</li>
    <li><strong>Tenencias pagadas</strong> (originales).</li>
    <li><strong>Baja de placas</strong> (ya no es necesario tarjeta de circulación).</li>
    <li><strong>Duplicado de llaves originales.</strong></li>
    <li><strong>Identificación oficial</strong> (INE/IFE, Cédula profesional o Pasaporte vigente).</li>
    <li><strong>Comprobante de domicilio </strong>(CFE, Agua o Telmex menor a 3 meses).</li>
    <li><strong>Carátula de estado de cuenta bancario</strong> (cuenta en la que  se realiza el pago por transferencia, debe ser a nombre del actual propietario del auto o a nombre del titular del auto).</li>
    <li><strong>Cédula de identificación fiscal</strong> (acreditación de actividad económica ante el SAT, en caso de no contar con ella nosotros la podemos conseguir).</li>
    
    </ul>
    
    <br />
    <strong>Dueño es empresa o persona con actividad empresarial:</strong>
    <br />
    
    <ul>
    <li><strong>Factura de origen </strong>(impresa).</li>
    <li><strong>Facturar a nombre de Autocom Seminuevos SAPI de CV </strong> (impresa y digital pdf/xml*).</li>
    <li><strong>Comprobante de compra-venta</strong> (refactura y/o endoso).</li>
    <li><strong>Tarjeta de circulación </strong>(vigente).</li>
    <li><strong>Comprobante verificación para autos con placas de Edo. Mex., Cdmx., Morelos </strong> (vigente).</li>
    <li><strong>Tenencias pagadas </strong>(originales).</li>
    <li><strong>Baja de placas </strong> (ya no es necesario tarjeta de circulación).</li>
    <li><strong>Duplicado de llaves originales.</strong></li>
    <li><strong>Carnet/libro de servicios.</strong></li>
    <li><strong>Acta constitutiva </strong> (persona moral).</li>
    <li><strong>Poder notarial </strong> (persona moral).</li>
    <li><strong>Identificación de representante legal o apoderado </strong> (persona moral).</li>
    <li><strong>Identificación oficial </strong>(INE/IFE, Cédula profesional o Pasaporte vigente).</li>
    <li><strong>Comprobante de domicilio </strong>(CFE, Agua o Telmex menor a 3 meses).</li>
    <li><strong>Carátula de estado de cuenta bancario </strong>(cuenta en la que se realiza el pago por transferencia, debe ser a nombre del actual propietario del auto o a nombre del titular del auto).</li>
    <li><strong>Cédula de identificación fiscal </strong>(acreditación de actividad económica ante el SAT, en caso de no contar con ella nosotros la podemos conseguir).</li>
    
    </ul>
    `,
  },

  {
    id: 5,
    question: '¿Puedo venderles mi auto si soy empresa o persona física con actividad empresarial?',
    answer: `
    Sí, nos facturas tu coche y entregas los documentos originales de tu auto como los entregaría una persona física y ¡listo!
    `,
  },

  {
    id: 6,
    question: '¿Puedo dejar mi auto a cuenta de otro?',
    answer: `
      Claro que sí. De esta forma pagarás menos por el auto que quieres estrenar. Además, el resto te lo podemos financiar para que pagues menos y no te descapitalices.
      <br />
      <br />
      Descubre ya en cuánto compramos tu auto para que estrenes el auto de tus sueños. Sólo tienes que llenar nuestro cotizador con los datos de tu auto y en menos de 5 minutos tendrás tu oferta para que nos dejes tu auto actual y te lleves el auto que siempre soñaste.
      <br />
    `,
  },

  {
    id: 7,
    question: '¿Cuánto tiempo tardan en realizar mi pago?',
    answer: `
      Una vez firmado tu contrato de compra venta, realizamos el pago en menos de 48hrs y recibes el monto total acordado en la cuenta bancaria que nos indicas.
      <br />
      <br />
      Es bueno mencionar que todos los pagos son realizados por depósito bancario, no realizamos pagos en efectivo.
    `,
  },

  {
    id: 8,
    question: '¿Pueden comprar mi auto si aún lo estoy pagando a la financiera?',
    answer: `
    Sí. Nos entregas tu auto con la baja de placas y todos los documentos originales, un Estado de Cuenta de tu crédito actualizado firmamos contrato de compra-venta.
Nosotros liquidamos directamente el adeudo con el banco o financiera
El banco te entrega la factura. Cuando nos entregas la factura original te pagamos el resto del $. 
    <br />
    <br />
    <b>**Aplica solo con Credinissan, Sicrea, Autocom Credit y Financieras que permitan a ClikAuto realizar el finiquito del crédito directamente a sus cuentas.</b>
    `,
  },

  {
    id: 9,
    question: '¿Puedo recibir un % de dinero al vender mi auto y dejar el resto a cuenta por otro auto?',
    answer: `
      Claro, al momento de que recibes una oferta de compra y nosotros compramos tu auto, tu puedes decidir que porcentaje de ese dinero quieres dejar como enganche para tu próximo auto; lo tomamos como referencia para cotizar un financiamiento personalizado y listo estarás estrenando auto nuevo sin descapitalizarte y con financiamiento personalizado.
    `,
  },

  {
    id: 10,
    question: '¿Qué es lo que inspeccionan de mi auto para que lo compren?',
    answer: `
     En la inspección de cada auto validamos el buen funcionamiento de piezas mecánicas, electrónicas y las óptimas condiciones de los interiores y exteriores, siguiendo nuestro Checklist de 156 puntos de inspección; mismo que darán la confianza de que el auto esta en perfecto funcionamiento, con esto tendremos la plena seguridad y confianza de comprar un auto en perfectas condiciones; ya que con esto podemos mantener la promesa de garantía de ClikAuto, autos seminuevos como nuevos.
    `,
  },

  {
    id: 11,
    question: '¿Se puede hacer la  inspección de mi auto por video llamada?',
    answer: `
    Sí, podemos realizar la inspección de tu auto por video llamada; es rápido, seguro y de inmediato.
    <br />
    <br />
    Sólo tienes que seleccionar la oferta de compra inmediata o a Consignación y agendar tu cita para la videollamada, indicando en el calendario el día y la hora en la que quieres que te contactemos para la inspección y listo; realizamos la inspección desde tu celular.
    `,
  },

  {
    id: 12,
    question: 'Después de la inspección, ¿qué sigue?',
    answer: `
    De acuerdo con las condiciones de tu auto procedemos a formalizar nuestra propuesta de compra. Cuando aceptas nuestra propuesta nos entregas toda la documentación original del auto, firmamos un contrato de compra-venta,  y en menos de 48hrs te pagamos por medio de transferencia y verás tu dinero reflejado en tu cuenta bancaria.
    `,
  },

  {
    id: 13,
    question: '¿Cómo es el pago al comprar mi auto? ¿Efectivo, transferencia o cheque?',
    answer: `
    Todos nuestros procesos de compra son realizados por transferencia electrónica interbancaria. Te solicitaremos una cuenta a la cual enviaremos el monto total de la compra de tu auto que aceptaste y se estipuló en el contrato de compra-venta que firmamos del cual tendrás una copia.
    <br />
    <br />
    Al realizar todas nuestras compras de esta forma, nos aseguramos que son transacciones seguras y confiables.
    `,
  },

  {
    id: 14,
    question: '¿Qué rango de años y kilometraje aceptan en los autos que compran?',
    answer: `
    El parámetro que tenemos para comprar un auto son sencillas, no tiene que ser mayor a 12 años de antigüedad y tenemos un máximo de 200,000 kms; si tu auto está dentro de este parámetro; cotiza ya y descubre la oferta de compra que tenemos para ti.
    `,
  },

  {
    id: 15,
    question: '¿Aceptan autos con detalles estéticos o problemas mecánicos?',
    answer: `
    Si tu auto tiene raspones o abolladuras con pérdida de pintura más grandes de 5cm lo consideraremos la reparación dentro de la oferta de compra. No compramos autos con reparaciones de chasis, motor, daño en transmisión o facturados de aseguradora.
    `,
  },
];

export const testimonials = [
  {
    id: 1,
    author: { avatar: '', name: 'Daniel Lucán' },
    text: 'Vendío su VW Jetta 2013 al mejor precio.',
    rating: 5,
    videoUrl: 'https://www.youtube.com/watch?v=q4l7MeOP8mo',
  },
  {
    id: 2,
    author: { avatar: '', name: 'Daniel Lucán' },
    text: 'Vendío su VW Jetta 2013 al mejor precio.',
    rating: 4,
    videoUrl: 'https://www.youtube.com/watch?v=q4l7MeOP8mo',
  },
  {
    id: 3,
    author: { avatar: '', name: 'Daniel Lucán' },
    text: 'Vendío su VW Jetta 2013 al mejor precio.',
    rating: 3,
    videoUrl: 'https://www.youtube.com/watch?v=q4l7MeOP8mo',
  },
];
export const countryCode = '52';

export const makers = ['ACURA', 'ALFA ROMEO', 'AUDI', 'BAIC', 'BMW', 'BUICK', 'CADILLAC', 'CHEVROLET', 'CHRYSLER', 'CUPRA', 'DODGE', 'FAW', 'FIAT', 'FORD', 'GMC', 'HONDA', 'HUMMER', 'HYUNDAI', 'INFINITI', 'ISUZU', 'JAC', 'JAGUAR', 'JEEP', 'KIA', 'LAND ROVER', 'LEXUS', 'LINCOLN', 'MAZDA', 'MERCEDES BENZ', 'MERCURY', 'MINI', 'MITSUBISHI', 'NISSAN', 'PEUGEOT', 'PONTIAC', 'PORSCHE', 'RAM', 'RENAULT', 'SAAB', 'SEAT', 'SMART', 'SUBARU', 'SUZUKI', 'TESLA', 'TOYOTA', 'VOLKSWAGEN', 'VOLVO'];

export const phone = '800 030 2886';

export const whatsapp = '525541704164';

export const HOTJAR_ID = 2217736;

export const SCHEDULE_VARIANT = {
  VIDEOCALL: 0,
  VISIT: 1,
};
