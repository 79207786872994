import {
  RECEIVE_FILTER,
  RESET_FILTER,
  TOGGLE_FILTER,
  REMOVE_FILTER,
  SET_FILTER,
  SET_FILTERS
} from '../actions/selectedFilters';

const initialState = {
  used: true
};

export default function selectedFilters(state = initialState, action) {
  switch (action.type) {
    case RESET_FILTER:
        return initialState;

    case SET_FILTER: {
      const { key, value="" } = action;
      return {
        ...state,
        [key]: value
      }
    }

    case SET_FILTERS: {
      const { filters } = action;
      return {
        ...state,
        ...filters
      }
    }


    case TOGGLE_FILTER: {
      const { key, value } = action;

      if (state[key] && state[key][value]) {
        return {
          ...state,
          [key]: {
            ...state[key],
            [value]: !state[key][value]
          }
        }
      } else {
        return {
          ...state,
          [key]: {
            ...state[key],
            [value]: true
          }
        }
      }
    }

    case REMOVE_FILTER: {
      const { key, value } = action;
      const filter = state[key];
      delete filter[value]

      return {
        ...state,
        [key]: { ...filter }
      }
    }

    default:
      return state;
  }
}
