import {
  RECEIVE_VEHICLES,
  ADD_VEHICLES
} from '../actions/inventory'

export default function inventory(state = [], action){
    switch(action.type){
        case RECEIVE_VEHICLES :
            return [...action.vehicles.inventory]
        case ADD_VEHICLES :
            return [...state, ...action.vehicles.inventory]
        default:
            return state
    }
}
