import React, { memo, useState } from 'react';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { WP_ROOT, APP_DOMAIN } from 'lib/api-config';
import styles from './styles';

const Header = (props) => {
  const {
    logo = '',
    invertedLogo = '',
    logoAltText = '',
    mainMenu = [],
    selectedMenuIndex = 0,
    inverted,
    position = 'relative',
    hideBackground = false,
    hideMenu = false,
    hideBackButton = false,
    classes,
  } = props;

  const history = useHistory();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileMenuOpen((prev) => (open === undefined ? !prev : open));
  };

  const appBarClassName = clsx(classes.appBar, {
    [classes.background]: !hideBackground,
    [classes.appBarInverted]: inverted,
  });

  const menuItemClassName = clsx(classes.menuItem, {
    [classes.menuItemInverted]: inverted,
  });

  const CurrentLogo = inverted ? invertedLogo : logo;

  const menu = (
    <Box className={classes.menu}>
      {mainMenu.map((item, index) => {
        const className = clsx(menuItemClassName, {
          [classes.menuItemSelected]: index === selectedMenuIndex,
          [classes.menuItemSelectedInverted]: index === selectedMenuIndex && inverted,
        });

        return (
          <Button
            key={item.id}
            className={className}
            component="a"
            href={item.url}
            size="small"
            variant="text"
          >
            {item.title}
          </Button>
        );
      })}
    </Box>
  );

  const mobileMenu = (
    <Box className={classes.menu}>
      <IconButton
        onClick={toggleDrawer()}
        color={inverted ? 'secondary' : 'secondary'}
        style={{ color: inverted ? 'white' : 'inherit' }}
        className={classes.toggleButton}
      >
        <MenuIcon fontSize="large" />
      </IconButton>

      <SwipeableDrawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        // swipeAreaWidth="100"
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={toggleDrawer()}
            color="secondary"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <List>
          {mainMenu.map((item, index) => {
            const className = clsx(classes.mobileMenuItem, {
              [classes.menuItemSelected]: index === selectedMenuIndex,
            });

            return (
              <ListItem
                key={item.id}
                className={className}
                component="a"
                href={item.url}
                divider
              >
                <ListItemText primary={item.title} />
              </ListItem>
            );
          })}
        </List>
      </SwipeableDrawer>
    </Box>
  );

  const goBack = () => {
    history.goBack();
  };

  return (
    <AppBar
      position={position}
      className={appBarClassName}
      elevation={inverted ? 4 : 0}
    >
      <Container maxWidth={false}>
        <Toolbar
          className={classes.toolBar}
          disableGutters
        >
          {!hideBackButton && (
            <Hidden mdUp>
              <IconButton
                className={classes.backButtonIcon}
                color="primary"
                onClick={goBack}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Hidden>
          )}

          <a href={WP_ROOT} className={classes.imgLogo}>
            {typeof logo === 'string' ? (
              <img
                src={CurrentLogo}
                alt={APP_DOMAIN}
                width="180"
                height="29"
              />
            ) : <CurrentLogo /> }
          </a>

          { !hideMenu && (
            <>
              <Hidden smDown>{menu}</Hidden>
              <Hidden mdUp>{mobileMenu}</Hidden>
            </>
          )}

        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default memo(withStyles(styles)(Header));
