import withStyles from '@material-ui/core/styles/withStyles'
import Rating from '@material-ui/lab/Rating'

const styles = theme => ({
  iconFilled: {
    color: theme.palette.primary.main
  },

  iconEmpty: {

  }
})

export default withStyles(styles)(Rating)
