const styles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& $titleContainer, $subtitleContainer, $swiperContainer': {
      marginBottom: theme.spacing(6)
    },

    [theme.breakpoints.up('sm')]: {
      backgroundImage: 'url(/clikauto/img/halfCircle.png)',
      backgroundSize: '91px',
      backgroundPosition: 'right bottom',
      backgroundRepeat:'no-repeat',
    },
  },

  titleContainer: {

  },

  title: {
    color: theme.palette.secondary.light,
  },

  subtitleContainer: {

  },

  subtitle: {
    marginBottom: theme.spacing(6)
  },

  swiperContainer: {

  },

  swiper: {
    padding: theme.spacing(),
    backgroundColor: 'unset'
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  itemIconContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex'
  },

  itemTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.dark
  },

  itemDescription: {

  },

  quoteButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: [
      [0, 12, 24, 'rgba(0, 0, 0, 0.2)']
    ],
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      width: '100%'
    }
  },

  rightImage: {
    position: 'absolute',
    width: 500,
    right: 0,
    bottom: -30,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },

    [theme.breakpoints.down('md')]: {
      width: 300,
      bottom: -30,
    },

    [theme.breakpoints.down('lg')]: {
      width: 400,
      bottom: -30,
      right: 100,
    }
  }
})

export default styles
