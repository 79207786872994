import {
  ADD_USER,
  ADD_USER_ERROR,
  UPDATE_USER,
  REMOVE_USER,
  REMOVE_USER_ERROR,
  ADD_TOKEN,
  ADD_PAYMENTS
} from "../actions/user";

export default function user(state = {}, action) {
  switch (action.type) {
    case ADD_USER:
      return { ...state, ...action.user };
    case ADD_USER_ERROR:
      return { errors: action.error };
    case REMOVE_USER_ERROR:
      return { ...state, errors: null };
    case UPDATE_USER:
      return { ...state, ...action.user };
    case REMOVE_USER:
      return {};
    case ADD_TOKEN:
      return { ...state, token: action.token };
    case ADD_PAYMENTS:
      return { ...state, payments: action.payments };
    default:
      return state;
  }
}
