import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Helmet from 'react-helmet';
import { withStyles } from '@material-ui/core';
import { PageDataContext } from 'new/hooks/usePageData';
import Header from '../Header';
import Footer from '../Footer';

const styles = (theme) => ({
  // backgroundMainContent: {
  //   backgroundImage: 'url(/clikauto/img/RIN2x.png)',
  //   backgroundSize: '300px',
  //   backgroundPosition: 'center -100px',
  //   backgroundRepeat: 'no-repeat',
  // }
});

const Page = (props) => {
  const {
    title,
    data = {},
    hideFooter = false,
    children,
    classes,
    hideBackground = false,
    hideMenu = false,
    hideBackButton = false,
    selectedMenuIndex = 0,
  } = props;

  const {
    mainMenu,
    footerMenu,
    makers,
  } = data;

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 200);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <PageDataContext.Provider value={data}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
        <title>{title}</title>
      </Helmet>

      <Header
        logo="/clikauto/img/logoBlue.svg"
        invertedLogo="/clikauto/img/logoWhite.svg"
        logoAltTex={process.env.REACT_APP_SITE_TITLE}
        mainMenu={mainMenu}
        selectedMenuIndex={selectedMenuIndex}
        inverted={scrolled}
        position={scrolled ? 'fixed' : 'relative'}
        hideBackground={hideBackground}
        hideMenu={hideMenu}
        hideBackButton={hideBackButton}
      />
      {scrolled && <Box style={{ marginBottom: 100 }} />}

      <Box className={hideBackground ? '' : classes.backgroundMainContent}>
        {children}
      </Box>

      {!hideFooter && (
        <Footer
          logoUrl="/clikauto/img/logoWhite.svg"
          logoAlt={process.env.REACT_APP_SITE_TITLE}
          mainMenu={footerMenu}
          footerMenu={[
            { title: 'Términos y condiciones', url: 'https://clikauto.com/aviso-de-privacidad/' },
            { title: 'Aviso legal', url: 'https://clikauto.com/aviso-de-privacidad/' },
            { title: 'Política de privacidad y cookies', url: 'https://clikauto.com/aviso-de-privacidad/' },
          ]}
          makers={makers}
        />
      )}
    </PageDataContext.Provider>
  );
};

export default withStyles(styles)(Page);
