import { ADD_LEAD, CLEAN_LEADS } from '../actions/leads';

export default function leads(state = { test: 'ola' }, action) {
  switch (action.type) {
    case ADD_LEAD:
      return { ...state, [action.lead_key]: action.lead_value };
    case CLEAN_LEADS:
      return {};
    default:
      return state;
  }
}
