import React, { memo } from 'react';
import { default as MuiTypography } from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  h1: {
    fontFamily: 'BigShouldersDisplay',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 64,
    lineHeight: '72px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 36,
      lineHeight: '48px',
    }
  },

  h2: {
    fontFamily: 'BigShouldersDisplay',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 48,
    lineHeight: '57px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      lineHeight: '44px'
    }
  },

  h3: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 32,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '34px',
    }
  },

  h4: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '30px',
    }
  },

  h5: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '26px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    }
  },

  h6: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '26px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '26px',
    }
  },

  body1: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '26px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '22px',
    }
  },

  body2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 14,
    lineHeight: '22px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '22px',
    }
  },

  caption: {
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '18px',
    }
  },

  button: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16
  },

  overline:{
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 16
  }
})

export default memo(withStyles(styles)(MuiTypography))
