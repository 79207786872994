import React, { useContext, useEffect, useRef } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Page from 'new/components/Page/Page';
import { mainMenu, whatsapp } from 'new/constants';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useAxios from 'new/hooks/useAxios';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import format from 'date-fns/format';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from 'new/redux/modules/common';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { MuiPickersContext } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { ReactComponent as FireIcon } from '../../components/icons/flama.svg';
import styles from './styles';
import { getVehicleLink } from '../../../lib/utils';

const PaymentConfirmation = ({ classes }) => {
  const { params, url } = useRouteMatch();
  const { vehicleId, orderId } = params;
  const history = useHistory();
  const dispatch = useDispatch();
  const { interestedLeadCreated } = useSelector((s) => s.common);
  const { locale } = useContext(MuiPickersContext);

  const [{ data: vehicle, loading: loadingVehicle, error: errorVehicle }] = useAxios(`inventory/${vehicleId}`);
  const [{ data: payment, loading: loadingPaymentData, error: errorPayment }] = useAxios({
    url: `payments/${vehicleId}`,
    method: 'PUT',
    data: {
      headers: { Accept: 'application/vnd.autocom.mx.v3' },
      payment: { vehicle_id: vehicleId, order_number: orderId },
    },
  });

  useEffect(() => {
    dispatch(setValue({ key: 'reservation', value: payment, persist: true }));
  }, [payment]);

  if (!vehicle || !payment) { return null; }

  const title = (
    <Typography variant="h2" className={classes.title}>
      {interestedLeadCreated
        ? `¡${payment.customer_hash.name}, está todo listo para que conozcas y estrenes pronto el auto de tus sueños!`
        : `${payment.customer_hash.name}, confirmamos el apartado de tu próximo auto:`}
    </Typography>
  );

  return (
    <Page
      data={{ mainMenu }}
      hideFooter
      hideMenu
      hideBackButton
      selectedMenuIndex={-1}
    >
      <Container maxWidth="md">
        <Hidden smDown>{title}</Hidden>

        <Box className={classes.wrapper}>
          <Grid
            container
            spacing={2}
          >
            <Hidden mdUp>
              <Grid item xs={12} className={classes.titleContainer}>
                {title}
              </Grid>
            </Hidden>

            <Grid
              item
              xs={12}
              sm={6}
              className={classes.vehicleDetails}
            >
              <Box className={classes.box1}>
                <Typography
                  variant="h3"
                >
                  {`${vehicle.year} ${vehicle.maker} ${vehicle.model}`}
                </Typography>

                <Typography
                  variant="h4"
                >
                  <NumberFormat
                    displayType="text"
                    value={vehicle.price}
                    prefix="$"
                    decimalScale={0}
                    thousandSeparator
                  />
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              container
              className={classes.buttonOptions}
              spacing={2}
            >
              <Grid
                item
                md={6}
                xs={6}
                className={classes.amount}
              >
                <Box className={classes.bgWhite}>
                  <Typography variant="h5">Monto:</Typography>
                  <Typography variant="h5" className={classes.blueText}>
                    <NumberFormat
                      displayType="text"
                      value={payment.amount}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      prefix="$"
                    />
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                xs={6}
                className={classes.orderId}
              >
                <Box className={classes.bgWhite}>
                  <Typography variant="h5">Reservación:</Typography>
                  <Typography variant="h5" className={classes.blueText}>
                    {orderId}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                md={12}
                className={classes.reserveExpiration}
              >
                <Box px={1}>
                  <Typography variant="body2">
                    Te recordamos que tu apartado es 100% reembolsable, en caso de que no realizar la compra del auto. El apartado tiene una vigencia de 72hrs.
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                md={12}
                className={classes.yourNextCar}
              >
                {interestedLeadCreated ? (
                  <Box
                    className={classes.box3}
                    component="div"
                  >
                    <Typography variant="h4" align="left" gutterBottom>
                      Conócelo el
                      {' '}
                      {' '}
                      {format(new Date(interestedLeadCreated.day), 'dd MMMM yyyy', { locale })}
                      {' | '}
                      {interestedLeadCreated.time}
                    </Typography>

                    <Typography variant="caption" align="left" gutterBottom>
                      {interestedLeadCreated.type === 'Videollamada'
                        ? 'Te llamaremos por WhatsApp para que lo conozcas, ten a la mano tu celular.'
                        : `Lo conocerás en la sucursal, que se encuentra en ${vehicle.seller.address}`}
                    </Typography>
                  </Box>
                ) : (
                  <ButtonBase
                    style={{ display: 'block', textAlign: 'left' }}
                    onClick={() => history.push(`/me-interesa/vehiculo/${vehicle.id}/conocelo/paso1`)}
                  >
                    <Box display="flex">
                      <Typography variant="h4" gutterBottom className={classes.yourNextCarTitle}>
                        Conoce ya tu próximo auto
                      </Typography>

                      <IconButton size="small" className={classes.rightIconButton}>
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>

                    <Typography variant="body2">
                      Sólo selecciona el día y hora para conocerlo desde tu casa o directamente en sucursal. ¡Recuerda que es GRATIS!
                    </Typography>
                  </ButtonBase>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h3" align="center" className={classes.bottomTitle}>
          ¿Qué es lo que sigue?
        </Typography>

        <Grid container spacing={4}>
          {[
            {
              img: 'conocelo',
              title: 'Conócelo hoy mismo',
              text: 'Puedes conocerlo por videollamada, en sucursal o lo llevamos hasta tu casa, agenda tu encuentro con tu próximo auto.',
            },
            {
              img: 'compra',
              title: 'Compra',
              text: 'Llévatelo de inmediato pagando de contado o desde el 20% de enganche y el resto a crédito flexible para que estrenes de inmediato.',
            },
            {
              img: 'estrena',
              title: 'Estrena de inmediato',
              text: 'Recibe directo en nuestra sucursal o en la puerta de tu casa, con garantía de 1/2 año y 7 días para que te enamores por completo.',
            },
          ].map((item, i) => (
            <Grid item md={4} key={i} className={classes.bottomGridItem}>
              <Box justifyContent="center" display="flex">
                <img src={`/clikauto/img/${item.img}.png`} alt={item.img} />
              </Box>
              <Typography variant="h5" align="center">
                {item.title}
              </Typography>

              <Typography variant="body2" align="center">
                {item.text}
              </Typography>

              <Divider className={classes.divider} />
            </Grid>
          ))}
        </Grid>

        {Boolean(interestedLeadCreated) && (
          <Button
            className={classes.acceptButton}
            color="primary"
            variant="contained"
            component="a"
            href={getVehicleLink(vehicle)}
          >
            Aceptar
          </Button>
        )}

        <Box p={2}>
          <Typography variant="caption">
            Esta información la recibirás por correo electrónico y recuerda que puedes contactarnos en cualquier momento en nuestro
            {' '}
            <a target="_blank" href={`//wa.me/${whatsapp}?text=Hola+quiero+hablar+con+un+asesor+ClikAuto.&app_absent=0`} rel="noreferrer">WhatsApp</a>
            ,
            {' '}
            {' '}
            o al
            {' '}
            <a href="tel:5589571916">558 957 1916</a>
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default withStyles(styles)(PaymentConfirmation);
