import React, { memo } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
  },

  label: {
    position: 'relative',
    fontWeight: 'regular',
    fontSize: 18,
    transform: 'none!important',
    margin: theme.spacing(0, 0, 1, 0),
  },

  noBorder: {
    border: 'none',
  },

  inputContainer: {
    color: theme.palette.text.secondary,
    position: 'relative',
    backgroundColor: '#F3F4FC',
    margin: theme.spacing(0, 0, 1, 0),
    marginTop: '0!important',
    borderRadius: theme.shape.borderRadius * 2,
    '&.Mui-disabled': {
      backgroundColor: '#EFEFEF',
    },
    '&.Mui-error': {
      backgroundColor: theme.palette.warning.main,
    },
  },

  input: {
    padding: theme.spacing(2),
  },

  placeholder: {

  },

  formHelperText: {
    margin: 0,
  },
});

const DatePicker = (props) => {
  const {
    classes,
    className,
    InputLabelProps,
    InputProps,
    FormHelperTextProps,
    ...other
  } = props;

  return (
    <MuiDatePicker
      className={clsx(className, classes.root)}

      InputLabelProps={{
        disableAnimation: true,
        shrink: true,
        className: classes.label,
        ...InputLabelProps,
      }}

      InputProps={{
        className: classes.inputContainer,
        classes: {
          notchedOutline: classes.noBorder,
          input: classes.input,
        },
        ...InputProps,
      }}

      FormHelperTextProps={{
        className: classes.formHelperText,
        ...FormHelperTextProps,
      }}

      inputVariant="outlined"
      {...other}
    />
  );
};

export default memo(withStyles(styles)(DatePicker));
