import { withStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { MuiPickersContext } from '@material-ui/pickers';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import format from 'date-fns/format';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import GoogleMap from 'new/components/Map';
import { useSelector } from 'react-redux';
import { isClikAuto } from 'lib/api-config';
// import { ButtonBase, Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import styles from './styles';

const getBranchName = (sellerName) => {
  let brancNew = sellerName;
  if (`${sellerName}`.toLowerCase().includes('nápoles') || `${sellerName}`.toLowerCase().includes('napoles')) {
    brancNew = 'ClikAuto Nápoles';
  } else if (`${sellerName}`.toLowerCase().includes('satélite') || `${sellerName}`.toLowerCase().includes('satelite')) {
    brancNew = 'ClikAuto Satelite';
  } else if (`${sellerName}`.toLowerCase().includes('sur')) {
    brancNew = 'ClikAuto Gran Sur';
  } else if (`${sellerName}`.toLowerCase().includes('verdes')) {
    brancNew = 'ClikAuto Lomas Verdes';
  } else if (`${sellerName}`.toLowerCase().includes('capilla')) {
    brancNew = 'ClikAuto La Capilla';
  } else if (`${sellerName}`.toLowerCase().includes('juriquilla')) {
    brancNew = 'ClikAuto Juriquilla';
  }

  return brancNew;
};

const Confirmation = (props) => {
  const {
    vehicle,
    classes,
    onButtonClick,
    location = {},
    lead,
  } = props;
  const { locale } = useContext(MuiPickersContext);
  const { interestedLeadCreated, reservation } = useSelector((s) => s.common);
  const history = useHistory();
  const { url } = useRouteMatch();
  const isEasyAgency = ((vehicle?.easy || vehicle?.consignacion)
    && vehicle?.ownerType === 'company'
    && vehicle?.holdingType === 'virtual') || (!vehicle?.authorizedSeller);

  const {
    day, time, name, whatsapp, type = 'visita', year, make, model,
  } = interestedLeadCreated || {};

  return (
    <>
      <Container
        maxWidth="md"
        className={classes.root}
      >
        <Typography
          variant="h2"
          className={classes.title}
        >
          <FormattedMessage
            id="NEW_LEAD_CONFIRMATION_TITLE"
            values={{
              name, type: `${type}`.toLowerCase(), year, make, model,
            }}
          />
        </Typography>

        <Typography
          variant="h5"
          className={classes.subtitle}
        >
          <FormattedMessage id="NEW_LEAD_CONFIRMATION_SUBTITLE" />
        </Typography>

        <Box className={classes.box}>
          <Typography
            variant="h4"
            align="left"
            className={classes.boxTitle}
          >
            <FormattedMessage id="NEW_LEAD_SCHEDULE_DETAILS" values={{ type: `${type} `.toLowerCase() }} />
          </Typography>

          <Box className={classes.innerBox}>
            <Typography
              variant="h4"
              className={classes.day}
            >
              {/* {format(new Date(`${day}T10:00:00`), 'dd MMMM yyyy', { locale })} */}
              {format(new Date(day), "dd 'de' MMMM yyyy", { locale })}
              {' | '}
              {time}
            </Typography>

            <Divider />

            <List dense className={classes.clientData}>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="body1">Nombre:</Typography>}
                  secondary={<Typography variant="body1">{name}</Typography>}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={<Typography variant="body1">Whatsapp:</Typography>}
                  secondary={<Typography variant="body1">{whatsapp}</Typography>}
                />
              </ListItem>

              {`${type}`.toLowerCase() === 'visita' && !isEmpty(location) && (
                <ListItem>
                  <ListItemText
                    primary={<Typography variant="body1">Ubicación:</Typography>}
                    secondary={(
                      <>
                        <Typography variant="body1" style={{ marginBottom: '16px' }}>
                          {isEasyAgency
                            ? getBranchName(vehicle?.seller?.branch?.name)
                            : getBranchName(vehicle?.sellerName)}
                        </Typography>
                        <GoogleMap
                          containerStyle={{
                            position: 'relative',
                            width: '100%',
                            height: 200,
                          }}
                          initialCenter={{
                            lat: location?.lat,
                            lng: location?.lng,
                          }}
                        />
                        <Typography
                          variant="body1"
                          className={classes.address}
                        >
                          {location?.address}
                        </Typography>
                      </>
                    )}
                  />
                </ListItem>
              )}
            </List>
          </Box>

          {/* <Grid
            item
            xs={12}
          >
            <ButtonBase
              className={classes.bannerSetIt}
              component="div"
              onClick={() => history.push(`/me-interesa/vehiculo/${vehicle.id}/apartado`)}
            >
              <Box>
                <Typography variant="h4">
                  <FormattedMessage id="SET_IT_ASIDE_2000" />
                </Typography>
              </Box>

              {!reservation && (
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                  <FormattedMessage id="IF_YOU_DONT_BUY_IT_DONT_WORRY" />
                </Typography>
              )}

              <Button
                variant="contained"
                fullWidth
                disableElevation
                className={classes.buttonBanner}
                onClick={onButtonClick}
              >
                <FormattedMessage id="RESERVE_A_CAR" />
              </Button>

            </ButtonBase>
          </Grid> */}

        </Box>

        <Button
          variant="contained"
          disableElevation
          className={classes.button}
          color="primary"
          onClick={onButtonClick}
        >
          <FormattedMessage id="BACK_TO_CLIKAUTO" />
        </Button>
      </Container>

      {isClikAuto && lead && (
        <script
          id="send-lead-id-confirmation"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
           dataLayer = [{
             'orderID': ${lead?.id}
           }]
      `,
          }}
        />
      )}
    </>

  );
};

export default withStyles(styles)(Confirmation);
