import React, { memo, useContext } from 'react';
import format from 'date-fns/format';
import { FormattedMessage, useIntl } from 'react-intl';
import { MuiPickersContext } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Divider } from '@material-ui/core';
import styles from './styles';

const ConfirmationBox = ({ classes, data }) => {
  const { locale } = useContext(MuiPickersContext);

  const content = JSON.parse(data.content);

  return (
    <Box>
      <Typography
        variant="h2"
        className={classes.title}
      >
        {data.title}
      </Typography>

      <Typography
        variant="h5"
        className={classes.subtitle}
      >
        {data.subtitle}
      </Typography>

      <Box className={classes.summaryContainer}>
        <Typography
          variant="h4"
          className={classes.summaryTitle}
        >
          {data.summaryTitle}
        </Typography>

        <Grid
          container
          className={classes.summaryInner}
        >
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
          >
            <Box mb={3}>
              <Typography
                variant="h4"
                className={classes.day}
              >
                {format(new Date(content.day), 'dd MMMM yyyy', { locale })}
                {' | '}
                {content.time}
              </Typography>
            </Box>

            <Divider className={classes.divider} />

            {content.name && (
              <Box mb={3} mt={3} className={classes.name}>
                <Typography variant="h3">
                  <FormattedMessage id="SCHEDULE_FORM_LABEL_NAME" />
                  :
                </Typography>

                <Typography variant="h3">
                  {content.name}
                </Typography>
              </Box>
            )}

            {content.whatsapp && data.type === 'videollamada' && (
              <Box mb={3} mt={3} className={classes.whatsapp}>
                <Typography variant="h3">
                  <FormattedMessage id="SCHEDULE_FORM_LABEL_WHATSAPP" />
                  :
                </Typography>

                <Typography variant="h3">
                  {content.whatsapp}
                </Typography>
              </Box>
            )}

            {content.city && (
              <Box mb={3} mt={3} className={classes.office}>
                <Typography variant="h3" gutterBottom>
                  ClikAuto:
                  {' '}
                  {content.city}
                </Typography>
                <Typography variant="caption">
                  Al confirmar tu visita, te compartiremos la dirección exacta de la ubicación del auto.
                </Typography>
              </Box>
            )}

            {data.note && (
              <Box>
                <Typography variant="body2">
                  {data.note}
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className={classes.iconContainer}
          >
            <Box>{data.icon}</Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        className={classes.bottomButtons}
        display="flex"
        justifyContent="center"
      >
        <Button
          component="a"
          id="schedule-form-accept-button"
          className={classes.button}
          variant="contained"
          color="primary"
          href={data.backUrl}
        >
          <FormattedMessage id="ACCEPT" />
        </Button>
      </Box>
    </Box>
  );
};

export default memo(withStyles(styles)(ConfirmationBox));
