import {RECEIVE_LINKS} from '../actions/links';
const initializeState = {
  copyright: [
    {
      title: 'Aviso de Privacidad',
      url: 'https://autocom.mx/aviso-de-privacidad/',
    },
  ],
  footers: {
    footer1: [{title: 'Autos en Venta', url: 'https://app.autocom.mx'}],
    footer2: [
      {
        title: 'Nissan Nuevos',
        url: 'https://autocom.mx/showroom-nuevos/nissan/',
      },
      {
        title: 'Infiniti Nuevos',
        url: 'https://autocom.mx/showroom-nuevos/infiniti/',
      },
    ],
    footer3: [
      {title: 'Financiamiento', url: 'https://autocom.mx/financiamiento/'},
    ],
    footer4: [
      {
        title: 'Vende',
        url: 'https://autocom.mx/vende-tu-auto/',
      },
    ],
    footer5: [
      {title: 'Nuestras Agencias', url: 'https://app.autocom.mx/ubicaciones'},
    ],
    footer6: [
      {title: '¿Por qué AUTOCOM?', url: 'https://autocom.mx/por-que-autocom/'},
      {title: 'Te escuchamos', url: 'https://autocom.mx/contactanos/'},
    ],
  },
  headers: [
    {title: '¿Por qué Autocom?', url: '/por-que-autocom/'},
    {
      title: 'Ubicación de Nuestras Agencias',
      url: 'https://app.autocom.mx/ubicaciones',
    },
  ],
  mainMenu: [
    {title: 'Autos en Venta', url: 'https://app.autocom.mx/'},
    {title: 'Catálogo Nuevos', url: 'https://autocom.mx/showroom-nuevos/'},
    {title: 'Vende', url: 'https://autocom.mx/vende-tu-auto/'},
    {title: 'Financiamiento', url: 'https://autocom.mx/financiamiento/'},
    {title: 'Seguros', url: 'https://autocom.mx/seguros/'},
    {title: 'Te Escuchamos', url: 'https://autocom.mx/contactanos/'},
  ],
  sidebar: [
    {title: '¿Por qué AUTOCOM?', url: 'https://autocom.mx/por-que-autocom/'},
    {
      title: 'Ubicación de Nuestras Agencias',
      url: 'https://app.autocom.mx/ubicaciones',
    },
    {title: 'Te escuchamos', url: 'https://autocom.mx/contactanos/'},
  ],
};

export default function compare(state = initializeState, action) {
  switch (action.type) {
    case RECEIVE_LINKS:
      return {...state, ...action.links};
    default:
      return state;
  }
}
