const initialState = {
    vdpModalOpen: localStorage.getItem('vdpModalOpen') || false,
    srpBannerManualClosed: localStorage.getItem('srpBannerManualClosed') || false
}

export default function loading(state = initialState, action){
    switch(action.type) {
        case 'SET_SHARED_VALUE': {
            const { key, value, persist } = action
            if (persist) {
                localStorage.setItem(key, String(value))
            }

            return {
                ...state,
                [key]: value
            }
        }

        default:
            return state
    }
}
