import axios from 'axios';
import qs from 'qs';
import { API_ROOT, APP_DOMAIN } from './api-config';

// // Generate a unique token for storing your bookshelf data on the backend server.
// let token = localStorage.token
// if (!token)
//     token = localStorage.token = Math.random().toString(36).substr(-8)
//
// const headers = { 'Accept': 'application/json', 'Authorization': token }
const headers = { Accept: 'application/vnd.autocom.mx.v3' };

export const getVehicles = async (params = {}, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  return axios
    .get(`${API_ROOT}/inventory`, { headers, params })
    .then((res) => res.data);
};

export const getVehiclesCount = (params = {}) => axios
  .get(`${API_ROOT}/inventory/count`, { headers, params })
  .then((res) => res.data);

export const getVehicle = async (id, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  return axios
    .get(`${API_ROOT}/inventory/${id}`, { headers })
    .then((res) => res.data);
};

export const getMarketingMessage = () => axios
  .get(`${API_ROOT}/inventory/marketing`, { headers })
  .then((res) => res.data);

export const getFilters = async (params = {}) => axios
  .get(`${API_ROOT}/filters`, { headers, params })
  .then((res) => res.data);

export const getModels = (maker, params = {}) => axios
  .get(`${API_ROOT}/filters/models`, {
    headers,
    params: { ...params, maker },
  })
  .then((res) => res.data);

export const getTrims = (model) => axios
  .get(`${API_ROOT}/filters/trims`, { headers, params: { model } })
  .then((res) => res.data);

export const getDealers = (params = {}) => axios
  .get(`${API_ROOT}/dealers`, { headers, params })
  .then((res) => res.data);

export const postLead = (params = {}) => {
  params.domain = APP_DOMAIN;
  return axios
    .post(`${API_ROOT}/leads`, { headers, lead: params })
    .then((res) => ({
      lead: res.data,
      status: res.status,
      statusText: res.statusText,
    }))
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const getFinancing = (params = {}) => axios
  .get(`${API_ROOT}/financing`, { headers, params })
  .then((res) => res.data);

export const postNewsletterSubscribe = (params = {}) => axios
  .post(`${API_ROOT}/inventory_alerts`, { headers, inventory_alert: params })
  .then((res) => ({
    subscription: res.data,
    status: res.status,
    statusText: res.statusText,
  }))
  .catch((error) => ({
    errors: error.response.data,
    status: error.response.status,
    statusText: error.response.statusText,
  }));

export const postNewsletterConfirmation = (id) => axios
  .put(`${API_ROOT}/inventory_alerts/${id}`, { headers })
  .then((res) => ({
    subscription: res.data,
    status: res.status,
    statusText: res.statusText,
  }))
  .catch((error) => ({
    errors: error.response.data,
    status: error.response.status,
    statusText: error.response.statusText,
  }));

export const postNewsletterUnsubscribe = (id) => axios
  .delete(`${API_ROOT}/inventory_alerts/${id}`, { headers })
  .then((res) => ({
    subscription: res.data,
    status: res.status,
    statusText: res.statusText,
  }))
  .catch((error) => ({
    errors: error.response.data,
    status: error.response.status,
    statusText: error.response.statusText,
  }));

export const postPayment = (token, params = {}, leadId, orderId) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: authorization,
      'content-type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({ payment: params, leadId, orderId }),
    url: `${API_ROOT}/payments`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const updatePayment = (id, params = {}) => axios
  .put(`${API_ROOT}/payments/${id}`, { headers, payment: params })
  .then((res) => res.data)
  .catch((error) => ({
    errors: error.response.data,
    status: error.response.status,
    statusText: error.response.statusText,
  }));

// User Authentication API
export const userSignUp = (params = {}) => axios
  .post(`${API_ROOT}/signup`, { headers, user: params })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const userConfirmation = (params = {}) => axios
  .get(`${API_ROOT}/confirmation`, { headers, params })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const userLogin = (params = {}) => axios
  .post(`${API_ROOT}/login`, { headers, user: params })
  .then((res) => {
    if (res.headers.authorization) {
      return {
        token: res.headers.authorization.split(' ')[1],
        user: res.data,
      };
    }
    return res.data;
  })
  .catch((error) => error.response.data);

export const userPasswordRequestToken = (params = {}) => axios
  .post(`${API_ROOT}/password`, { headers, user: params })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const userPasswordReset = (params = {}) => axios
  .patch(`${API_ROOT}/password`, { headers, user: params })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const userLogout = (token) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/logout`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const userDelete = (token, params = {}) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data: { user: params },
    url: `${API_ROOT}/users`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};
// User Authentication API

// Authneticated API

export const myUser = (token) => axios
  .get(`${API_ROOT}/users`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
    params: {},
  })
  .then((res) => res.data)
  .catch((error) => ({ errors: 'Unauthorized' }));

export const userUpdate = (token, params = {}) => {
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      'content-type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({ user: params }),
    url: `${API_ROOT}/users`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error);
};

export const userUpdatePassword = (token, params = {}) => {
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      'content-type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({ user: params }),
    url: `${API_ROOT}/users/update_password`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myPayments = async (token) => axios
  .get(`${API_ROOT}/my/payments`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
    params: {},
  })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const myAlerts = async (token) => axios
  .get(`${API_ROOT}/my/inventory_alerts`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
    params: {},
  })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const myAlertsDelete = async (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/my/inventory_alerts/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myFavorites = async (token) => axios
  .get(`${API_ROOT}/my/favorites`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
    params: {},
  })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const myFavoriteAdd = async (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/my/favorites`,
    data: { id },
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};
export const myFavoriteDelete = async (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/my/favorites/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myMessages = async (token, params = {}) => axios
  .get(`${API_ROOT}/my/messages`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
    params,
  })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const myMessage = (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/my/messages/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myMessageDelete = (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/my/messages/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myMessageTerminate = (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/my/messages/${id}/terminate`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myMessageReopen = (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/my/messages/${id}/reopen`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myMessageRead = (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/my/messages/${id}/read`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myMessageReport = (token, id, params = {}) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data: params,
    url: `${API_ROOT}/my/messages/${id}/report`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const myMessageCreate = (token, params = {}) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data: params,
    url: `${API_ROOT}/my/messages/create_thread`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};
export const myMessageAdd = (token, params = {}) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data: params,
    url: `${API_ROOT}/my/messages`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const sellerVehicles = (token, params = {}) => axios
  .get(`${API_ROOT}/seller/vehicles`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
    params,
  })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const sellerVehicle = (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/vehicles/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const vinLookup = (token, id, plate) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/vehicles/vin_lookup/${id}`,
    params: { license_plate: plate },
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const vinInspection = async (token, id, plate) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/vehicles/vin_inspection/${id}`,
    params: { license_plate: plate },
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const vinFeatures = (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/vehicles/vin_features/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};
export const vinPrice = (token, id) => {
  // Must to include the Token in the Headers
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/vehicles/vin_price/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getStates = () => axios
  .get(`${API_ROOT}/states`, { headers })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const getExteriorColors = () => axios
  .get(`${API_ROOT}/exterior_colors`, { headers })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const getInteriorColors = () => axios
  .get(`${API_ROOT}/interior_colors`, { headers })
  .then((res) => res.data)
  .catch((error) => error.response.data);

export const addVehicle = async (token, params = {}) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: authorization,
      'content-typdde': 'application/x-www-form-urlencoded',
    },
    data: { vehicle: params },
    url: `${API_ROOT}/seller/vehicles`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const updateVehicle = async (token, id, params = {}) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: authorization,
    },
    data: { vehicle: params },
    url: `${API_ROOT}/seller/vehicles/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const removeVehicle = (token, id, params = {}) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: authorization,
    },
    // data: { vehicle: params },
    url: `${API_ROOT}/seller/vehicles/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const publishVehicle = (token, id) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: authorization,
    },
    url: `${API_ROOT}/seller/vehicles/${id}/publish`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const getVehicleImages = (token, vehicleId, params = {}) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: authorization,
    },
    url: `${API_ROOT}/seller/vehicles/${vehicleId}/photos`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const addVehicleImage = (token, vehicleId, params = {}) => {
  const authorization = token ? `Bearer ${token}` : null;
  const formData = new FormData();
  formData.append('vehicle_image[image]', params.image, 'vehicle-image.jpg');
  formData.append('vehicle_image[position]', params.position);

  const config = {
    headers: {
      ...headers,
      Authorization: authorization,
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  };

  return axios
    .post(`${API_ROOT}/seller/vehicles/${vehicleId}/photos`, formData, config)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const updateVehicleImage = (token, vehicleId, id, params = {}) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: authorization,
    },
    data: { vehicle_image: params },
    url: `${API_ROOT}/seller/vehicles/${vehicleId}/photos/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};
export const removeVehicleImage = (token, vehicleId, id, params = {}) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: authorization,
    },
    url: `${API_ROOT}/seller/vehicles/${vehicleId}/photos/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

export const sellerOcrImageAdd = (token, image) => {
  const authorization = token ? `Bearer ${token}` : null;
  const formData = new FormData();
  formData.append('ocr_image[image]', image.data, image.name);

  const config = {
    headers: {
      ...headers,
      Authorization: authorization,
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  };
  return axios
    .post(`${API_ROOT}/seller/ocr_images`, formData, config)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getAllMakes = async (token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/vehicle/makes`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getAllModels = async (make, year, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/vehicle/models`,
    params: { make, year },
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getAllVersions = async (model, year, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/vehicle/versions`,
    params: { model, year },
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getEstimatedMarketPrice = async (params, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/estimated_market_value`,
    params,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const getEstimatedMarketPriceInventory = async (id, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/estimated_market_value/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const uploadInventoryCsv = async (token, file) => {
  const authorization = token ? `Bearer ${token}` : null;
  const formData = new FormData();
  formData.append('batch_file[inventory_file]', file, file.name);

  const config = {
    headers: {
      ...headers,
      Authorization: authorization,
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  };
  return axios
    .post(`${API_ROOT}/seller/batch_files`, formData, config)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getBatchUploadStatus = async (token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/batch_files`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getSellerImports = async (filter, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/imports`,
    params: { filter },
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getBatchRunVehicleArchiveReason = async (id, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/imports/archive/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getVehicleBuyers = async (id, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/imports/archive/${id}/buyers`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const createBatchRunVehicleArchiveReason = async (
  token,
  params = {},
) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: authorization,
      'content-type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({ archive: params }),
    url: `${API_ROOT}/seller/imports/archive`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const updateBatchRunVehicleArchiveReason = async (
  id,
  token,
  params = {},
) => {
  const authorization = token ? `Bearer ${token}` : null;
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
      Authorization: authorization,
      'content-type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({ archive: params }),
    url: `${API_ROOT}/seller/imports/archive/${id}`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getVehicleModels = async (id, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/vehicles/${id}/models`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const getVehicleVersions = async (id, token = null) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers = {
    Authorization: authorization,
  };
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    url: `${API_ROOT}/seller/vehicles/${id}/versions`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const getAcuantBearerToken = async (token = null) => axios
  .get(`${API_ROOT}/id_verifications/bearer_token`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  })
  .then((res) => res.data)
  .catch((error) => ({
    errors: error.response.data,
    status: error.response.status,
    statusText: error.response.statusText,
  }));

export const createIdVerification = async (images = {}, token = null) => {
  const formData = new FormData();

  if (images.frontImage) {
    formData.append(
      'id_verification[front_image]',
      images.frontImage,
      'front_picture.jpg',
    );
  }
  if (images.backImage) {
    formData.append(
      'id_verification[back_image]',
      images.backImage,
      'back_picture.jpg',
    );
  }
  if (images.selfieImage) {
    formData.append(
      'id_verification[selfie_image]',
      images.selfieImage,
      'selfie_image.jpg',
    );
  }

  const config = {
    headers: {
      ...{ Accept: 'application/vnd.autocom.mx.v3' },
      Authorization: token ? `Bearer ${token}` : null,
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  };

  return axios
    .post(`${API_ROOT}/id_verifications`, formData, config)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const updateIdVerification = async (id, images = {}, token = null) => {
  const formData = new FormData();

  if (images.frontImage) {
    formData.append(
      'id_verification[front_image]',
      images.frontImage,
      'front_picture.jpg',
    );
  }
  if (images.backImage) {
    formData.append(
      'id_verification[back_image]',
      images.backImage,
      'back_picture.jpg',
    );
  }
  if (images.selfieImage) {
    formData.append(
      'id_verification[selfie_image]',
      images.selfieImage,
      'selfie_image.jpg',
    );
  }

  const config = {
    headers: {
      ...{ Accept: 'application/vnd.autocom.mx.v3' },
      Authorization: token ? `Bearer ${token}` : null,
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  };

  return axios
    .patch(`${API_ROOT}/id_verifications/${id}`, formData, config)
    .then((res) => res.data)
    .catch((error) => ({
      errors: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};

export const verifyId = async (id, token = null) => axios
  .get(`${API_ROOT}/id_verifications/${id}/verify_id`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  })
  .then((res) => res.data)
  .catch((error) => ({
    errors: error.response.data,
    status: error.response.status,
    statusText: error.response.statusText,
  }));

export const verifySelfie = async (id, token = null) => axios
  .get(`${API_ROOT}/id_verifications/${id}/verify_selfie`, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  })
  .then((res) => res.data)
  .catch((error) => ({
    errors: error.response.data,
    status: error.response.status,
    statusText: error.response.statusText,
  }));
export const createCreditApplication = async (params = {}) => {
  const options = {
    method: 'POST',
    headers: {
      ...headers,
    },
    data: qs.stringify({ credit_application: params }),
    url: `${API_ROOT}/credit_applications`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const createCreditApplicationSubmission = async (id, params = {}) => {
  const options = {
    method: 'POST',
    headers: {
      ...headers,
    },
    data: qs.stringify(params),
    url: `${API_ROOT}/my/credit_applications/${id}/submissions`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
export const updateCreditApplicationNip = async (id, nip) => {
  const options = {
    method: 'PATCH',
    headers: {
      ...headers,
    },
    data: qs.stringify({ nip }),
    url: `${API_ROOT}/credit_applications/${id}/nip`,
  };
  return axios(options)
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    }));
};
