import { withStyles } from '@material-ui/core/styles';
import { IS_PROD } from 'lib/api-config';
import Container from '@material-ui/core/Container';
import React, { memo, useEffect } from 'react';
import styles from './styles';

const StepOneForm = withStyles(styles)((props) => {
  const { vehicle } = props;
  // redirect to new NextJS application
  useEffect(() => {
    const basePath = IS_PROD
      ? 'https://cita.clikauto.com'
      : 'https://clikauto-cita-git-staging-clikauto.vercel.app';
    window.location.replace(`${basePath}/me-interesa/vehiculo/${vehicle?.id}/conocelo/paso1`);
  }, []);

  return (
    <Container
      maxWidth="sm"
      style={{ marginBottom: '50px' }}
    />
  );
});

export default memo(StepOneForm);
