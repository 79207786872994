import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sellYourCarFormOpen: true,
  interestedLeadCreated:
    JSON.parse(sessionStorage.getItem('interestedLeadCreated')) || null,
  reservation: JSON.parse(sessionStorage.getItem('reservation')) || null,
  oldStates: JSON.parse(localStorage.getItem('autocomState')) || {},
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    toggleSellYourCarForm: (state, action) => {
      state.sellYourCarFormOpen = !state.sellYourCarFormOpen;
    },

    setValue: (state, action) => {
      const { key, value, persist } = action.payload;
      if (persist) {
        sessionStorage.setItem(
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        );
      }
      state[key] = value;
    },
  },
});

export default commonSlice.reducer;
export const { toggleSellYourCarForm, setValue } = commonSlice.actions;
