import axios from 'axios';
import React from 'react';
import {
  APP_DOMAIN, APP_ENV, APP_ROOT, IS_PROD,
} from 'lib/api-config';

// export const autocomPhoneNumber = '(800) 711-2886';
export const autocomPhoneNumber = '(800) 030 2886';
export const googleMapsApiKey = 'AIzaSyCLK9M0V30eFrsh7GQM76rfq2LrmCeGmig';

export function versionKeyTranslate(key) {
  const translation = {
    name: 'Nombre',
    enginePower: 'Potencia',
    fuel: 'Combustible',
    transmission: 'Transmisión',
    traction: 'Tracción',
    powerSteering: 'Tipo de Dirección',
    interiorMaterial: 'Interiores',
    airbags: 'Bolsas de Aire',
    airConditioning: 'Aire Acondicionado',
    powerWindows: 'Vidrios Eléctricos',
    sunroof: 'Quemacocos',
    stereo: 'Estereo',
    rimType: 'Tipo de Rin',
    rimSize: 'Rin',
    tireSize: 'Neumáticos',
    frontBakes: 'Frenos Delanteros',
    backBrakes: 'Frenos Traseros',
    frontSuspension: 'Suspensión Delantera',
    backSuspension: 'Suspensión Trasera',
    cabinType: 'Tipo de Cabina',
    roof: 'Techo',
    passengers: 'Pasajeros',
    valves: 'Valvulas',
    segment: 'Segmento',
    class: 'Categoria',
    doors: 'Puertas',
    bodyType: 'Tipo de Vehículo',
    engineSize: null,
    cylinders: 'Cilindros',
  };
  return translation[key] ? translation[key] : 'missing translation';
}

export function getCoordinates(address) {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleMapsApiKey}`;
  return axios.get(url).then((res) => res.data);
}

export function getCoordinatesFromZipcode(zipcode) {
  // const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode} MX&key=${googleMapsApiKey}&region=MX`;
  const mapsAPI = 'https://maps.googleapis.com/maps/api/geocode/json';
  const key = `key=${googleMapsApiKey}`;
  const query = `address=${zipcode} MX&region=MX&components=country:MX`;
  // const query = `address=${zipcode} MX&region=MX`;
  // const query = `components=postal_code:${zipcode}|country:MX`;
  // const query = `address=${zipcode}&components=country:MX`;
  // const query = `address=${zipcode},Mexico`;

  const url = `${mapsAPI}?${query}&${key}`;
  return axios.get(url).then((res) => {
    const result = res.data.results && res.data.results[0];
    const coordinates = (result && result.geometry && result.geometry.location) || {};
    const city = (result
        && result.address_components
        && result.address_components.find((addr) => addr.types.includes('locality', 'political')))
      || '';
    const state = (result
        && result.address_components
        && result.address_components.find((addr) => addr.types.includes('administrative_area_level_1', 'political')))
      || '';
    return {
      coordinates,
      city,
      state,
    };
  });
}

export function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, (m) => m.toUpperCase());
}

export function daysAgo(date) {
  const today = new Date();
  const createdOn = new Date(date);
  const msInDay = 24 * 60 * 60 * 1000;

  createdOn.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  return (+today - +createdOn) / msInDay;
}

export function formatNumber(
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign
      + (j ? i.substr(0, j) + thousands : '')
      + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
      + (decimalCount
        ? decimal
          + Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

export function splitStringToList(list, name) {
  let newValue; let
    listArray;
  if (list === undefined) {
    listArray = [];
  } else {
    listArray = list.toLowerCase().split(',');
  }
  if (listArray.includes(name.toLowerCase())) {
    newValue = listArray.filter((v) => v !== name.toLowerCase());
  } else {
    newValue = [...listArray, name.toLowerCase()];
  }
  return newValue.join(',');
}

export function includesKey(value, compareValue) {
  if (value === undefined) return false;
  return value.toLowerCase().split(',').includes(compareValue.toLowerCase());
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function isZero(value) {
  return value === 0 || value.length === 0 || value === '';
}

export function yearRange(startYear) {
  const currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

export function camelToSnake(key) {
  const result = key.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toLowerCase();
}

export function snakeToCamel(str) {
  return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function hashCamelCaseToSnakeCase(obj) {
  return Object.entries(obj).reduce(
    (acc, curr) => ({ ...acc, [camelToSnake(curr[0])]: curr[1] }),
    {},
  );
}
export function hashSnakeToCamelCase(obj) {
  return Object.entries(obj).reduce(
    (acc, curr) => ({ ...acc, [snakeToCamel(curr[0])]: curr[1] }),
    {},
  );
}
export function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${year}/${month}/${day}`;
}

export const formatDateEasy = (date) => {
  const arrayDate = date.includes('/') ? date.split('/') : date.split('-');
  const dateSelected = new Date(
    parseInt(arrayDate[0]),
    parseInt(arrayDate[1] - 1),
    parseInt(arrayDate[2]),
  );
  const today = new Date();
  let text;
  if (sameDay(dateSelected, today)) {
    text = 'Hoy,';
  } else if (sameDay(dateSelected, addDays(today, 1))) {
    text = 'Mañana,';
  } else {
    text = '';
  }
  return `${text} ${dateSelected.toLocaleString('es-MX', { weekday: 'long', day: 'numeric' })}`;
};

export function validateRFC(rfc) {
  const RFCRegex = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;
  return Boolean(rfc.match(RFCRegex)) && rfc.length === 13;
}

export const getVehicleLink = (vehicle) => `//${APP_DOMAIN}/vehiculo/${vehicle.maker}-${vehicle.model}-${vehicle.year}-${vehicle.city}/${vehicle.id}${window.location.search}`
  .replaceAll(' ', '-')
  .toLowerCase();

export const getVehicleLinkById = (vehicleId) => `//${APP_DOMAIN}/vehiculo/${vehicleId}${window.location.search}`
  .replaceAll(' ', '-')
  .toLowerCase();

export const formatTransmission = (value) => {
  if (`${value}`.includes('AUT')) {
    return 'AUT';
  } if (`${value}`.includes('MANUAL')) {
    return 'MANUAL';
  }

  return `${value}`;
};

export const sameDay = (first, second) => first.getFullYear() === second.getFullYear()
  && first.getMonth() === second.getMonth()
  && first.getDate() === second.getDate();

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getTimeSlots = (from, to, slots) => Array(((to - from) * slots.length) + 1)
  .fill(0)
  .map((_, i) => `${(Math.floor(i / slots.length)) + from}:${slots[i % 2]}`);

export const formatHour = (hour) => {
  const parts = hour.split(':');
  let am = true;
  const h = parseInt(parts[0]);

  if (h > 11) {
    am = false;
  }

  return `${h == 12 ? h : h % 12}:${parts[1]} ${am ? 'AM' : 'PM'}`;
};

// snippet of code to determine if the vehicle is from 'Satelite' location. We dont have a flag to determine
// if the vehicle has an office that support videocall, so for now we need this hardcoded function
export const canScheduleVideocall = (vehicle) => (
  (APP_ENV === 'PRODUCTION' && vehicle.dealerId === 12)
    || (APP_ENV === 'STAGING' && vehicle.dealerId === 30)
    || (APP_ENV === 'INTEGRATION' && vehicle.dealerId === 13)
);

export const isPromoCodeValid = (code) => {
  const VALID_COUPONS = {
    BERTH21: true,
    BERT21: true,
    FER21: true,
    XUXO21: true,
  };

  return VALID_COUPONS[`${code}`.toUpperCase()];
};

export const replaceImageDomain = (imagePath) => (APP_ENV === 'PRODUCTION'
  ? imagePath.replace('https://images.autocomseminuevos.mx', 'https://images.clikauto.com')
  : imagePath.replace('https://staging-images.autocomseminuevos.mx', 'https://dj0fev86c5kks.cloudfront.net'));

/**
 * This method is a utility to lineal handling of promises that could possibly be rejected
 */
export const safePromise = async (promise) => {
  let result = null;
  let error = null;
  try {
    result = await promise;
  } catch (err) {
    error = err;
  }
  return {
    result,
    error,
  };
};
