const styles = (theme) => ({
  background: {
    // backgroundImage: 'url(/clikauto/img/RIN2x.png)',
    // backgroundSize: '300px',
    // backgroundPosition: 'center 0px',
    // backgroundRepeat: 'no-repeat'
  },

  appBar: {
    backgroundColor: 'transparent',
    height: 100,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(),
      height: 'auto',
    },
  },

  toolBar: {
    [theme.breakpoints.down('sm')]: {
      '& img': {
        height: 21,
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },

  imgLogo: {
    '& img': {
      maxWidth: 180,
    },
  },

  appBarInverted: {
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: 'none',
    [theme.breakpoints.only('xs')]: {
      boxShadow: 'none',
    },
  },

  toggleButton: {
    padding: '0px',
  },

  menu: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },

  menuItem: {
    color: theme.palette.primary.main,
    textTransform: 'none!important',
    margin: theme.spacing(),
    whiteSpace: 'nowrap',
    fontSize: '1rem!important',
    lineHeight: '26px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    borderRadius: '4px!important',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2),
    },
    '&.MuiButton-sizeSmall': {
      height: 40,
      padding: `${theme.spacing(2)}px!important`,
    },
  },

  mobileMenuItem: {
    color: theme.palette.primary.main,
    margin: 0,
    width: 200,
    fontWeight: 400,
    textAlign: 'center',
    '& span': {
      fontWeight: '500!important',
    },
  },

  menuItemSelected: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  menuItemInverted: {
    color: theme.palette.secondary.contrastText,
  },

  menuItemSelectedInverted: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  breadcrumbsLink: {
    color: theme.palette.text.primary,
  },

  breadcrumbsLinkInverted: {
    color: theme.palette.secondary.contrastText,
  },

  breadcrumbsText: {
    color: theme.palette.text.secondary,
  },

  breadcrumbsTextInverted: {
    color: theme.palette.text.primary,
  },

  backButtonIcon: {
    marginLeft: -16,
  },
});

export default styles;
