export const RELOAD_INVENTORY = 'RELOAD_INVENTORY';
export const SCREEN_POSITION = 'SCREEN_POSITION';

export function reloadInventory(reloadInventory = false) {
  return {
    type: RELOAD_INVENTORY,
    reloadInventory,
  };
}
export function updateScreenPosition(positionY) {
  return {
    type: SCREEN_POSITION,
    positionY,
  };
}

export function handleReloadInventory(params) {
  return dispatch => {
    dispatch(reloadInventory(params));
  };
}
export function handleUpdatePosition(params) {
  return dispatch => {
    dispatch(updateScreenPosition(params));
  };
}
