import React from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

const MapContainer = ({ google, ...options}) => {
  return (
    <Map
      google={google}
      zoom={16}
      {... options}
    >
      <Marker />
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyBBmywcXPu2-FvA7rU9j2iwKl9BU_hq0HU')
})(MapContainer)
