const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },

  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    textAlign: 'left!important',
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('md')]: {
      textAlign: 'center!important',
    },
  },

  subtitle: {
    marginBottom: theme.spacing(4),
    textAlign: 'left!important',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center!important',
    },
  },

  box: {
    backgroundColor: theme.palette.background.primary,
    maxWidth: 600,
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },

  clientData: {
    '& .MuiTypography-root.MuiTypography-body1': {
      fontWeight: '600',
    },
  },

  address: {
    marginTop: theme.spacing(1),
    fontWeight: '300!important',
  },
  boxTitle: {
    marginBottom: theme.spacing(2),
  },
  innerBox: {
    backgroundColor: '#F2F2F2',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  day: {
    marginBottom: theme.spacing(2),
  },
  button: {
    display: 'block',
    margin: '0 auto',
    minWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 326,
    },
  },
  buttonBanner: {
    marginTop: theme.spacing(2),
    height: theme.spacing(5),
    backgroundColor: theme.palette.text.white,
  },
  bannerSetIt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
  },
});

export default styles;
