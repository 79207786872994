const styles = theme => ({
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      borderRadius: 8,
      padding: theme.spacing(5),
      backgroundColor: '#FBFBFB'
    }
  },

  titleContainer: {
    [theme.breakpoints.down('xs')]: {
      order: 2
    },
  },

  buttonOptions: {
    order: 2,
  },

  title: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      marginBottom: theme.spacing(5),
    },
  },

  vehicleDetails: {
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },

  box1: {
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(10),
      '& .MuiTypography-h3, .MuiTypography-h4': {
        textAlign: 'center'
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    }
  },

  bgWhite: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 4
  },

  blueText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },

  textPrimary: {
    color: theme.palette.secondary.main
  },

  bottomTitle: {
    margin: theme.spacing(5, 0, 5, 0),
    color: theme.palette.secondary.main
  },

  bottomGridItem: {
    position: 'relative',
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  },

  divider: {
    position: 'absolute',
    bottom: 0,
    left: 40,
    right: 40,
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  },

  amount: {
    backgroundColor: theme.palette.background.tile
  },

  orderId: {
    backgroundColor: theme.palette.background.tile
  },

  reserveExpiration: {
    backgroundColor: theme.palette.background.tile
  },

  yourNextCar: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      '& $bgWhite': {
        background: 'none'
      }
    },

    '& > .MuiButtonBase-root': {
      padding: `${theme.spacing(2)}px!important`,
      borderRadius: 8,
      backgroundColor: '#EFEFEF',
    }
  },

  yourNextCarTitle: {
    color: '#122549',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px!important'
    }
  },

  rightIconButton: {
    marginLeft: 'auto',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    width: 25,
    height: 25,
    color: theme.palette.primary.main
  },

  box3: {
    backgroundColor: '#EFEFEF',
    height: '100%',
    padding: theme.spacing(2),
    display: 'block'
  },

  acceptButton: {
    display: 'block',
    margin: theme.spacing(7, 'auto', 2, 'auto'),
    width: 'fit-content',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      minWidth: 326,
    }
  }
})

export default styles