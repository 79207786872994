const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    backgroundColor: 'black!important',
    boxShadow: 'none',
    opacity: '0.87!important',
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  line1: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },

  line2: {
    textAlign: 'center',
    height: 100
  }
})

export default styles
