import React, { memo, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormattedMessage } from 'react-intl'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import usePageData from 'new/hooks/usePageData'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Faq1 from './Faq1'
import styles from './styles'

const Transition = React.forwardRef(function Transition(props, ref) {
  console.log(props)
  return <Slide direction="up" ref={ref} {...props} />
})

const Section4 = (props) => {
  const { classes } = props
  const { faqs } = usePageData()
  const [activeFaq, setActiveFaq] = useState(null)

  const closeFaq = () => setActiveFaq(null)

  const dialog = (
    <React.Fragment>
      <Dialog
        open={activeFaq !== null}
        onClose={closeFaq}
        fullScreen
        TransitionComponent={Transition}
      >
        <Faq1 faq={activeFaq} onClose={closeFaq} />
      </Dialog>
    </React.Fragment>
  )

  return (
    <Box className={classes.root}>
      <Container className={classes.faq}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
          >
            <Typography
              variant="h3"
              className={classes.title}
            >
              <FormattedMessage id="WHAT_COMPANY_IS_AND_HOW_IT_WORKS" />
            </Typography>

          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
          >
            <Divider className={classes.customDividerRight} />

            <List>
              {faqs.map((item, index) => (
                <ListItem
                  key={item.id}
                  onClick={() => setActiveFaq(faqs[index])}
                  alignItems="flex-start"
                  className={classes.listItem}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="h4"
                        className={classes.faqLink}
                      >
                        {item.question}
                      </Typography>}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Container>

      <Box className={classes.bottomButtons}>
        <Container>
          <Grid
            container
            spacing={6}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                variant="h3"
                className={classes.bottomButtonsLeftText}
                align="left"
              >
                <FormattedMessage id="KNOW_OUR_INVENTORY" />
              </Typography>

              <Box display="flex" justifyContent="flex-start">
                <Button
                  className={classes.bottomButtonsLeftButton}
                  component="a"
                  href="https://app.clikauto.com"
                >
                  <FormattedMessage id="FIND_YOUR_CAR" />
                </Button>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                variant="h3"
                className={classes.bottomButtonsRightText}
              >
                <FormattedMessage id="GET_THE_CAR_OF_YOUR_DREAMS" />
              </Typography>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.bottomButtonsRightButton}
                  component="a"
                  href="https://clikauto.com/financiamiento"
                >
                  <FormattedMessage id="GET_FINANCING" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {activeFaq && dialog}
    </Box>
  )
}

export default memo(withStyles(styles)(Section4))
