const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'space-between',
    },
  },

  title: {
    color: theme.palette.secondary.light,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    }
  },

  subtitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    }
  },

  quoteButton: {
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.contrastText,

    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
      width: '100%',
    }
  },

  right: {
    display: 'flex',
    flexDirection: 'column'
  },

  swiperBg: {
    maxWidth: '100%',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content'
    }
  },

  swiper: {
    marginTop: -152,
    padding: theme.spacing(7, 3, 2, 8),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3),
      marginTop: -34,
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3),
    }
  },

  swiper2: {
    marginTop: -102,
    padding: theme.spacing(7, 3, 2, 8),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3),
      marginTop: -34,
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3),
    }
  },

  swiperActiveIndex: {
    position: 'absolute',
    left: -30,
    top: -30,
    width: 102,
    height: 102,
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.contrastText,
    borderRadius: '50%',
    '& span': {
      fontSize: 64,
      fontWeight: 900,
      fontFamily: 'BigShouldersDisplay'
    },
    [theme.breakpoints.down('sm')]: {
      '& span': {
        fontSize: '30px!important',
      },
      width: 40,
      height: 40,
      left: -10,
      top: -10,
    }
  },

  swiperPagination: {
    position: 'absolute',
    bottom: theme.spacing(2),
    alignSelf: 'center',
  },

  swiperNavigation: {
    // marginTop: -20,
    alignSelf: 'flex-end',
    order: 1,
    '& button': {
      margin: theme.spacing(.5),
      width: 40,
      height: 40,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      [theme.breakpoints.only('xs')]: {
        width: 30,
        height: 30,
      }
    },
  },

  moreButton: {
    display: 'block',
    margin: 'auto',
    marginTop: 90,
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    // Reference a global .button scoped to the container.
    "& .MuiButton-label": {
      display: 'flex',
      flexDirection: 'column'
    }
  },
})

export default styles
