const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },

  faq: {
    flexGrow: 1,
    padding: theme.spacing(5, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  },

  title: {
    color: theme.palette.secondary.main
  },

  customDivider: {
    height: '32px',
    backgroundColor: '#FFE81D',
    borderRadius: '30px',
    marginTop: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  customDividerRight: {
    height: 22,
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: '30px',
    marginBottom: theme.spacing(4)
  },

  listItem: {
    marginBottom: theme.spacing(3),
    padding: 0
  },

  faqLink: {
    cursor: 'pointer'
  },

  bottomButtons: {
    padding: theme.spacing(7, 0),
    background: `linear-gradient(to left, ${theme.palette.primary.main} 50%, #4F91EB 50%)`,
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
      background: `linear-gradient(to top, ${theme.palette.primary.main} 50%, #8C46ED 50%)`,
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(2, 0),
    }
  },

  bottomButtonsLeftText: {
    color: theme.palette.secondary.contrastText,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    }
  },

  bottomButtonsRightText: {
    color: theme.palette.secondary.contrastText,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
      textAlign: 'right'
    }
  },

  bottomButtonsLeftButton: {
    backgroundColor: theme.palette.background.default,
    color: '#4F91EB',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'unset'
    }
  },

  bottomButtonsRightButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'unset'
    }
  }
})

export default styles
