import React, { memo, lazy, Suspense, useEffect } from 'react'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import { useIntl } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import Page from 'new/components/Page'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import styles from './styles'
import { makers, mainMenu, footerMenu, testimonials, faqs } from 'new/constants'
import { Route, Switch } from 'react-router-dom'

const Form = lazy(() => import('./Form'))

const SellYourCar = (props) => {
  const { classes } = props

  const { formatMessage: f } = useIntl()

  return (
    <Switch>
      <Route path="/vende-tu-auto" exact render={() => (
        window.location.replace(`https://clikauto.com/vende-tu-auto`)
        // <Page
        //   data={{ mainMenu, footerMenu, makers, testimonials, faqs }}
        //   title={`${f({ id: 'pageTitleSellYourCar' })} | ${process.env.REACT_APP_SITE_TITLE}`}
        // >
        //   <section className={clsx(classes.section, classes.section1)}>
        //     <Section1 />
        //   </section>

        //   <section className={clsx(classes.section, classes.section2)}>
        //     <Section2 />
        //   </section>

        //   <section>
        //     <Section3 />
        //   </section>

        //   <section>
        //     <Section4 />
        //   </section>
        // </Page>
      )} />

      <Route to="/vende-tu-auto/cotiza" render={() => (
        window.location.replace(`https://clikauto.com/vende-tu-auto-cotizador/antiguedad`)
        // <Page
        //   data={{ mainMenu, footerMenu, makers, testimonials, faqs }}
        //   title={`${f({ id: 'pageTitleSellYourCar' })} | ${process.env.REACT_APP_SITE_TITLE}`}
        //   hideFooter
        // >
        //   <Dialog
        //     open
        //     fullScreen
        //   >
        //     <Suspense fallback={null}>
        //       <Form />
        //     </Suspense>
        //   </Dialog>
        // </Page>
      )}/>
    </Switch>
  )
}

export default memo(withStyles(styles)(SellYourCar))
