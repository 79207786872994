import * as WP from '../../lib/wordpress_api';

export const RECEIVE_LINKS = 'RECEIVE_LINKS';

export function receiveLinks(links) {
  return {
    type: RECEIVE_LINKS,
    links,
  };
}
export function handleGetLinks() {
  return async dispatch => {
    const headers = await WP.getHeaderLinks();
    const mainMenu = await WP.getMainMenuLinks();
    const sidebar = await WP.getSidebarLinks();
    const copyright = await WP.getCopyrightLinks();
    // const social = await WP.getSocialLinks();
    const footers = await WP.getFooterLinks();
    dispatch(
      receiveLinks({headers, mainMenu, sidebar, copyright, footers}),
    );
  };
}
