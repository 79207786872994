const styles = (theme) => ({
  wrapper: {
    maxWidth: 634,
    margin: '0 auto',
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      borderRadius: 8,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.tile,
    },
  },

  titleContainer: {
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },

  select: {
    marginBottom: theme.spacing(2),
  },

  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },

  vehicleDetails: {
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },

  buttonOptions: {
    order: 2,
  },

  box1: {
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(10),
      '& .MuiTypography-h3, .MuiTypography-h4': {
        textAlign: 'center',
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },

  box2: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(2),
    display: 'block',
  },

  offer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main,
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      '& .MuiTypography-root:first-child': {
        lineHeight: '0!important',
      },
    },
  },

  offerTimer: {
    width: 90,
    backgroundColor: theme.palette.secondary.main,
    marginRight: -13,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(0.5, 1),
    [theme.breakpoints.up('sm')]: {
      borderRadius: 32,
      padding: theme.spacing(1, 3, 1, 1),
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 16,
      width: 100,
    },
  },

  offerIcon: {
    backgroundColor: '#DE5B5B!important',
    margin: -8,
  },

  box3: {
    backgroundColor: '#EFEFEF',
    height: '100%',
    padding: theme.spacing(2),
    display: 'block',
  },

  dialog: {
    padding: theme.spacing(4),
    '& .MuiTypography-h4, .MuiTypography-caption': {
      color: '#1F1F1F',
    },

    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
    },

    '& .MuiButton-root': {
      margin: '0 auto',
      display: 'block',
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        minWidth: 424,
      },
    },

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),

      '& .MuiTypography-h4': {
        fontSize: '20px!important',
      },
    },
  },

  openPayBox: {
    borderRadius: 24,
    maxWidth: 634,
    margin: '0 auto',
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    backgroundColor: '#F7F9FB',
    color: '#1F1F1F',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },

  openPayLogoContainer: {
    height: 'fit-content',
    borderRadius: 24,
    backgroundColor: '#FFFFFF',
    '& img': {
      maxWidth: '100%',
    },
  },

  disabled: {
    opacity: 0.7,
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },

  button: {
    margin: '24px auto',
    display: 'block',
  },

  field: {
    marginBottom: theme.spacing(2),
  },
  disclaimer: {
    maxWidth: 634,
    margin: '0 auto',
    background: '#049A80',
    color: '#ffffff',
  },
});

export default styles;
