export const ADD_COMPARE = 'ADD_COMPARE';
export const REMOVE_COMPARE = 'REMOVE_COMPARE';

export function addToCompare(vehicle) {
  return {
    type: ADD_COMPARE,
    vehicle,
  };
}
export function removeFromCompare(id) {
  return {
    type: REMOVE_COMPARE,
    id,
  };
}
