import React, { useEffect, useState } from 'react';
import Page from 'new/components/Page';
import useAxios from 'new/hooks/useAxios';
import {
  Route,
  useRouteMatch,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { ReactComponent as VideocallIcon } from 'new/components/icons/videocall.svg';
import { IS_PROD, APP_DOMAIN } from 'lib/api-config';
import { withStyles } from '@material-ui/core/styles';
import RequestDialog from 'new/components/RequestModal';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch } from 'react-redux';
import { setValue } from 'new/redux/modules/common';
import { SCHEDULE_VARIANT } from 'new/constants';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import qs from 'query-string';
import Confirmation from './Confirmation';
import StepOneForm from './StepOneForm';
import Reserve from './Reserve';
import Home from './Home';

// eslint-disable-next-line react/prop-types
const CreateLead = ({ classes }) => {
  const { params, url } = useRouteMatch();
  const [requestDialog, setRequestDialog] = useState(null);
  const { vehicleId } = params;
  const [
    { data: vehicle },
  ] = useAxios(`inventory/${vehicleId}`);
  const history = useHistory();
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [isEasyVirtualVehicle, setIsEasyVirtualVehicle] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [{ data: lead }, saveLead] = useAxios(
    { url: 'leads' },
    { manual: true },
  );
  const isParticular = vehicle?.ownerType === 'particular';
  const branch = isParticular
    ? vehicle?.branch
    : vehicle?.seller?.branch;

  const [{ data: duplicatedLead }] = useAxios(
    { url: 'leads' },
    { manual: true },
  );

  const PROGRESS_VALUE = {
    [url]: 0,
    [`${url}/apartado`]: 0,
    [`${url}/conocelo/paso1`]: 33,
    [`${url}/apartado`]: 33,
    [`${url}/conocelo/paso2`]: 66,
  };

  const easyVirtualVehicleLat = vehicle?.vehicleAppointments[0]?.branchLat;
  const easyVirtualVehicleLng = vehicle?.vehicleAppointments[0]?.branchLng;
  const easyVirtualVehicleAddress = vehicle?.vehicleAppointments[0]?.branchAddress;

  const lat = ((isEasyVirtualVehicle) ? easyVirtualVehicleLat : `${branch?.lat}`);
  const lng = ((isEasyVirtualVehicle) ? easyVirtualVehicleLng : `${branch?.log}`);
  const address = ((isEasyVirtualVehicle) ? easyVirtualVehicleAddress : branch?.address);
  const sellerName = (isEasyVirtualVehicle
    ? vehicle?.vehicleAppointments[0]?.branchName : branch?.name);

  const location = {
    lat, lng, address, sellerName,
  };

  const showDateDialog = () => {
    setRequestDialog({
      title: 'Un momento, tu visita está siendo agendada',
      icon: <VideocallIcon />,
    });

    setTimeout(() => {
      history.push(`${url}/conocelo/confirmacion/sucursal`);
      setLoadingSimulation(false);
    }, 3000);
  };

  const onForm1Submit = (formData) => {
    saveLead({
      method: 'POST',
      data: {
        origin: window.location.origin,
        domain: APP_DOMAIN,
        form_name: 'VDP - AGENDA VISITA',
        content: JSON.stringify({
          ...formData,
          vehicleId: vehicle?.id,
          vehicleVIN: vehicle?.vin,
          year: vehicle?.year,
          make: vehicle?.maker,
          model: vehicle?.model,
          price: vehicle?.price,
          vehicleVersion: vehicle?.trim,
          authorizedSeller: vehicle?.authorizedSeller,
          dealerId: vehicle?.dealerId,
          inspection_location_id: branch?.branchId,
          branch_id: branch?.branchId,
          inspection_location: branch?.name,
          ...qs.parse(window.location.search),
        }),
      },
    });
    setLoadingSimulation(true);
  };

  useEffect(() => {
    if (lead) {
      const JSONContent = JSON.parse(lead?.content);
      dispatch(
        setValue({
          key: 'interestedLeadCreated',
          value: JSONContent,
          persist: true,
        }),
      );
      showDateDialog();
    }
  }, [lead, history]);

  useEffect(() => {
    if (duplicatedLead) {
      const JSONContent = JSON.parse(duplicatedLead.content);
      dispatch(
        setValue({
          key: 'interestedLeadCreated',
          value: JSONContent,
          persist: true,
        }),
      );
      const dynamicUrl = JSONContent.type === 'Videollamada' ? 'videollamada' : 'sucursal';
      history.push(`${url}/conocelo/confirmacion/${dynamicUrl}`);
    }
  }, [duplicatedLead, history]);

  useEffect(() => {
    const isVirtual = ('virtual');
    const easyAppointmentName = vehicle?.vehicleAppointments[0]?.name.toLowerCase();
    const easyVirtualVehicle = (easyAppointmentName === isVirtual) && (vehicle?.easy === true);

    setIsEasyVirtualVehicle(easyVirtualVehicle);
  }, [vehicle]);

  const goBack = () => {
    history.goBack();
  };

  if (!vehicle) return null;

  const showBackButton = () => pathname !== `${url}/conocelo/confirmacion`
    && !pathname.includes('payment_redirect');

  const isEasyParticular = 'ClikAuto Easy: Particular (virtual)';

  if (!vehicle.seller.allowVideocall
    && !vehicle.seller.allowVisit
    && vehicle.seller.name
    && !isEasyParticular
  ) {
    history.push('/404');
  }

  const BASE_PATH = IS_PROD
    ? `https://clikauto.com/vehiculo/${vehicle.id}`
    : '/';

  return (
    <Page hideMenu hideFooter hideBackButton={!showBackButton()}>
      <Grid container alignItems="center">
        {showBackButton() && (
          <Hidden smDown>
            <Grid item md={2}>
              <Button
                className={classes.backButton}
                startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                size="small"
                onClick={goBack}
              >
                <FormattedMessage id="BACK_BUTTON" />
              </Button>
            </Grid>
          </Hidden>
        )}

        <Grid item md={8} xs={12}>
          {PROGRESS_VALUE[window.location.pathname] > 0 && (
            <Box className={classes.linearProgressWrapper}>
              <LinearProgress
                className={classes.linearProgress}
                variant="determinate"
                value={PROGRESS_VALUE[window.location.pathname]}
                classes={{
                  bar: classes.linearProgressBar,
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Switch>
        <Route
          path={`${url}/conocelo/paso1`}
          exact
          render={() => (
            <>
              <StepOneForm
                vehicle={vehicle}
                onSubmit={onForm1Submit}
                lead={lead}
                variant={SCHEDULE_VARIANT.VISIT}
                location={location}
                isEasyVirtualVehicle={isEasyVirtualVehicle}
              />

            </>
          )}
        />

        <Route
          path={`${url}/conocelo/confirmacion/sucursal`}
          exact
          render={() => (
            <Confirmation
              vehicle={vehicle}
              lead={lead}
              onButtonClick={() => { window.location.href = `${BASE_PATH}`; }}
              location={location}
            />
          )}
        />

        <Route
          path={`${url}/apartado`}
          render={() => <Reserve vehicle={vehicle} />}
        />

        <Route path={url} render={() => <Home vehicle={vehicle} />} />
      </Switch>

      {loadingSimulation && <RequestDialog open {...requestDialog} />}
    </Page>
  );
};

export default withStyles((theme) => ({
  linearProgressWrapper: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.highlight,
      borderRadius: theme.shape.borderRadius * 8,
      overflow: 'hidden',
    },
  },

  linearProgress: {
    borderRadius: theme.shape.borderRadius * 8,
    backgroundColor: theme.palette.background.highlight,
    boxSizing: 'content-box',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginLeft: -5,
      marginRight: -5,
      height: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(2),
    },
  },

  linearProgressBar: {
    borderRadius: theme.shape.borderRadius * 8,
  },
}))(CreateLead);
