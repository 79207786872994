const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(8, 0)
  },

  logo: {
    width: 150
  },

  borderRight:{
    borderRight:'1px solid white'
  },

  textWhite: {
    color: theme.palette.secondary.contrastText,
  },

  makers: {
    textTransform: 'capitalize'
  },

  menu: {
    margin: 0,
    padding: 0
  },

  menuItem: {
    color: theme.palette.secondary.contrastText,
    listStyleType: 'none',
    '& a': {
      color: theme.palette.secondary.contrastText,
    }
  },

  menuItemTitle:{
    fontFamily:'Poppins',
    fontWeight: 600,
    fontSize: 18,
    lineHeight:'26px',
    color: 'white'
  },

  divider: {
    backgroundColor: theme.palette.secondary.contrastText,
  },

  copyRight: {

  }
})

export default styles
