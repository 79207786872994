import {ADD_COMPARE, REMOVE_COMPARE} from '../actions/compare';

export default function compare(state = [], action) {
  switch (action.type) {
    case ADD_COMPARE:
      if(state.find(vehicle => vehicle.id === action.vehicle.id)){
        return [...state]
      }else{
        return [...state, action.vehicle];
      }
    case REMOVE_COMPARE:
      return state.filter(vehicle => vehicle.id !== action.id);
    default:
      return state;
  }
}
