import React, { useEffect, useState } from 'react';
import {
  Switch, Route, useRouteMatch, useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import useAxios from 'new/hooks/useAxios';
import Page from 'new/components/Page';
import ScheduleForm from 'new/components/ScheduleForm';
import ConfirmationBox from 'new/components/ConfirmationBox';
import RequestModal from 'new/components/RequestModal';
import { ReactComponent as CalendarIcon } from 'new/components/icons/calendar-search.svg';
import { ReactComponent as VideocallIcon } from 'new/components/icons/videocall.svg';
import Grid from '@material-ui/core/Grid';
import { getVehicleLink } from 'lib/utils';
import Box from '@material-ui/core/Box';
import { APP_DOMAIN } from 'lib/api-config';

const PAGE_TITLES = {
  videollamada: 'SCHEDULE_FORM_TITLE_VIDEOCALL',
  visita: 'SCHEDULE_FORM_TITLE_VISIT',
};

const PAGE_SUBTITLES = {
  videollamada: 'SCHEDULE_FORM_SUBTITLE_VIDEOCALL',
  visita: 'SCHEDULE_FORM_SUBTITLE_VISIT',
};

const PAGE_SUMMARY_TITLES = {
  videollamada: 'SCHEDULE_FORM_SUMMARY_TITLE',
  visita: 'SCHEDULE_FORM_SUMMARY_TITLE',
};

const LOADING_TITLES = {
  videollamada: 'SCHEDULE_FORM_LOADING_VIDEOCALL_TITLE',
  visita: 'SCHEDULE_FORM_LOADING_VISIT_TITLE',
};

const LOADING_ICONS = {
  videollamada: <VideocallIcon />,
  visita: <CalendarIcon />,
};

const CONFIRMATION_TITLES = {
  videollamada: 'SCHEDULE_FORM_CONFIRMATION_VIDEOCALL_TITLE',
  visita: 'SCHEDULE_FORM_CONFIRMATION_VISIT_TITLE',
};

const CONFIRMATION_SUBTITLE = {
  videollamada: 'SCHEDULE_FORM_CONFIRMATION_VIDEOCALL_SUBTITLE',
  visita: 'SCHEDULE_FORM_CONFIRMATION_VISIT_SUBTITLE',
};

const CONFIRMATION_SUMMARY_SUBTITLE = {
  videollamada: 'CONFIRMATION_SUMMARY_VIDEOCALL_SUBTITLE',
  visita: 'CONFIRMATION_SUMMARY_VISIT_SUBTITLE',
};

const CONFIRMATION_NOTES = {
  videollamada: 'CONFIRMATION_VIDEOCALL_NOTES',
  visita: 'CONFIRMATION_VIDEOCALL_NOTES',
};

const ScheduleFormPage = () => {
  const [requestDialog, setRequestDialog] = useState(null);
  const { params } = useRouteMatch();
  const history = useHistory();
  const { type, vehicleId } = params;

  const [
    { data: vehicle, loading: loadingVehicle, error: errorVehicle },
  ] = useAxios(`inventory/${vehicleId}`);

  const leads = useSelector((store) => store.common.oldStates.leads);

  const [{ data: response, loading, error }, saveLead] = useAxios(
    {
      url: 'leads',
    },
    { manual: true },
  );

  const backUrl = vehicle ? getVehicleLink(vehicle) : '';

  const data = {
    title: <FormattedMessage id={PAGE_TITLES[type]} />,
    subtitle: <FormattedMessage id={PAGE_SUBTITLES[type]} />,
    summaryTitle: <FormattedMessage id={PAGE_SUMMARY_TITLES[type]} />,
    city: vehicle ? vehicle.city : '',
    type,
    backUrl,
  };

  const confirmationData = {
    title: <FormattedMessage id={CONFIRMATION_TITLES[type]} />,
    subtitle: <FormattedMessage id={CONFIRMATION_SUBTITLE[type]} />,
    summaryTitle: <FormattedMessage id={CONFIRMATION_SUMMARY_SUBTITLE[type]} />,
    icon: LOADING_ICONS[type],
    note:
      type === 'videollamada' ? (
        <FormattedMessage id={CONFIRMATION_NOTES[type]} />
      ) : (
        ''
      ),
    type,
    backUrl,
  };

  const onSubmit = (data) => {
    setRequestDialog({
      title: <FormattedMessage id={LOADING_TITLES[type]} />,
      icon: LOADING_ICONS[type],
    });

    setTimeout(() => {
      saveLead({
        method: 'POST',
        data: {
          origin: window.location.origin,
          domain: APP_DOMAIN,
          form_name:
            type === 'videollamada' ? 'AGENDAR VIDEOLLAMADA' : 'AGENDAR VISITA',
          content: JSON.stringify({
            type: data.type,
            vehicleId,
            vehicleVIN: vehicle.vin,
            year: vehicle.year,
            make: vehicle.maker,
            model: vehicle.model,
            price: vehicle.price,
            authorizedSeller: vehicle.authorizedSeller,
            dealerId: vehicle.dealerId,
            ...data,
            ...leads,
          }),
        },
      });
      setRequestDialog(null);
    }, 3000);
  };

  useEffect(() => {
    if (response) {
      history.push(`/agendar/${type}/${vehicleId}/confirmacion`);
    }
  }, [response]);

  return vehicle ? (
    <Page data={{}} hideFooter hideBackground>
      <Container component={Box} mb={4}>
        <Switch>
          <Route
            path={`/agendar/${type}/${vehicleId}`}
            exact
            render={() => (
              <>
                <ScheduleForm data={data} onSubmit={onSubmit} />

                <RequestModal
                  open={requestDialog !== null}
                  {...requestDialog}
                />
              </>
            )}
          />

          <Route
            path={`/agendar/${type}/${vehicleId}/confirmacion`}
            render={() => (response ? (
              <Grid container justify="center" />
            ) : null)}
          />
        </Switch>
      </Container>
    </Page>
  ) : null;
};

export default ScheduleFormPage;
