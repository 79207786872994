import {
  GET_VEHICLE
} from '../actions/inventory'

export default function vehicle(state = {}, action){
    switch(action.type){
        case GET_VEHICLE :
            return action.vehicle
        default:
            return state
    }
}
