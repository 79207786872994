import React, { memo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import styles from './styles'

const FacebookIcon = (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0001 0.719727C6.66675 0.719727 0.666748 6.70639 0.666748 14.0797C0.666748 20.7464 5.54675 26.2797 11.9201 27.2797V17.9464H8.53341V14.0797H11.9201V11.1331C11.9201 7.78639 13.9067 5.94639 16.9601 5.94639C18.4134 5.94639 19.9334 6.19973 19.9334 6.19973V9.49306H18.2534C16.6001 9.49306 16.0801 10.5197 16.0801 11.5731V14.0797H19.7867L19.1867 17.9464H16.0801V27.2797C19.222 26.7835 22.083 25.1804 24.1467 22.7598C26.2103 20.3392 27.3405 17.2606 27.3334 14.0797C27.3334 6.70639 21.3334 0.719727 14.0001 0.719727Z" fill="white" />
    </svg>
  </SvgIcon>
)

const InstagramIcon = (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.40008 0.666992H19.6001C23.8668 0.666992 27.3334 4.13366 27.3334 8.40033V19.6003C27.3334 21.6513 26.5187 23.6183 25.0684 25.0686C23.6181 26.5189 21.6511 27.3337 19.6001 27.3337H8.40008C4.13341 27.3337 0.666748 23.867 0.666748 19.6003V8.40033C0.666748 6.34932 1.48151 4.38231 2.93179 2.93203C4.38207 1.48175 6.34907 0.666992 8.40008 0.666992ZM8.13341 3.33366C6.86038 3.33366 5.63948 3.83937 4.7393 4.73955C3.83913 5.63972 3.33341 6.86062 3.33341 8.13366V19.867C3.33341 22.5203 5.48008 24.667 8.13341 24.667H19.8667C21.1398 24.667 22.3607 24.1613 23.2609 23.2611C24.161 22.3609 24.6667 21.14 24.6667 19.867V8.13366C24.6667 5.48033 22.5201 3.33366 19.8667 3.33366H8.13341ZM21.0001 5.33366C21.4421 5.33366 21.866 5.50925 22.1786 5.82181C22.4912 6.13438 22.6667 6.5583 22.6667 7.00033C22.6667 7.44235 22.4912 7.86628 22.1786 8.17884C21.866 8.4914 21.4421 8.66699 21.0001 8.66699C20.5581 8.66699 20.1341 8.4914 19.8216 8.17884C19.509 7.86628 19.3334 7.44235 19.3334 7.00033C19.3334 6.5583 19.509 6.13438 19.8216 5.82181C20.1341 5.50925 20.5581 5.33366 21.0001 5.33366ZM14.0001 7.33366C15.7682 7.33366 17.4639 8.03604 18.7141 9.28628C19.9644 10.5365 20.6667 12.2322 20.6667 14.0003C20.6667 15.7684 19.9644 17.4641 18.7141 18.7144C17.4639 19.9646 15.7682 20.667 14.0001 20.667C12.232 20.667 10.5363 19.9646 9.28604 18.7144C8.03579 17.4641 7.33342 15.7684 7.33342 14.0003C7.33342 12.2322 8.03579 10.5365 9.28604 9.28628C10.5363 8.03604 12.232 7.33366 14.0001 7.33366ZM14.0001 10.0003C12.9392 10.0003 11.9218 10.4218 11.1717 11.1719C10.4215 11.922 10.0001 12.9395 10.0001 14.0003C10.0001 15.0612 10.4215 16.0786 11.1717 16.8288C11.9218 17.5789 12.9392 18.0003 14.0001 18.0003C15.0609 18.0003 16.0784 17.5789 16.8285 16.8288C17.5787 16.0786 18.0001 15.0612 18.0001 14.0003C18.0001 12.9395 17.5787 11.922 16.8285 11.1719C16.0784 10.4218 15.0609 10.0003 14.0001 10.0003Z" fill="white" />
    </svg>

  </SvgIcon>
)

const YoutubeIcon = (
  <SvgIcon>
    <svg width="24" height="20" viewBox="0 -2 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3334 14.0003L18.2534 10.0003L11.3334 6.00033V14.0003ZM26.7467 3.56033C26.9201 4.18699 27.0401 5.02699 27.1201 6.09366C27.2134 7.16033 27.2534 8.08033 27.2534 8.88033L27.3334 10.0003C27.3334 12.9203 27.1201 15.067 26.7467 16.4403C26.4134 17.6403 25.6401 18.4137 24.4401 18.747C23.8134 18.9203 22.6667 19.0403 20.9067 19.1203C19.1734 19.2137 17.5867 19.2537 16.1201 19.2537L14.0001 19.3337C8.41341 19.3337 4.93341 19.1203 3.56008 18.747C2.36008 18.4137 1.58675 17.6403 1.25341 16.4403C1.08008 15.8137 0.960081 14.9737 0.880082 13.907C0.786748 12.8403 0.746748 11.9203 0.746748 11.1203L0.666748 10.0003C0.666748 7.08033 0.880081 4.93366 1.25341 3.56033C1.58675 2.36033 2.36008 1.58699 3.56008 1.25366C4.18675 1.08033 5.33342 0.960325 7.09342 0.880325C8.82675 0.786992 10.4134 0.746992 11.8801 0.746992L14.0001 0.666992C19.5867 0.666992 23.0667 0.880326 24.4401 1.25366C25.6401 1.58699 26.4134 2.36033 26.7467 3.56033Z" fill="white" />
    </svg>
  </SvgIcon>
)


const Footer = (props) => {
  
  const {
    logoUrl = '',
    logoAltText = '',
    mainMenu = [],
    footerMenu = [],
    selectedMenuItemIndex = 0,
    makers = [],
    classes,
    classNames
  } = props

  const facebookUrl = "https://www.facebook.com/ClikAuto";
  const instagramUrl = "https://www.instagram.com/clikauto_/";

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
        >
          <Grid item md={3}>
            <Grid container className={classes.borderRight}  spacing={2}>
              <Grid item>
                <img
                  className={classes.logo}
                  src={logoUrl}
                  alt={logoAltText}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.textWhite}
                  variant="h5"
                >
                  ¡Cambiamos, compramos o vendemos tu auto por ti!
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item component="a" href={facebookUrl} target="_blank">
                    {FacebookIcon}
                  </Grid>
                  <Grid item component="a" href={instagramUrl} target="_blank">
                    {InstagramIcon}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.textWhite}
                  variant="body2"
                >
                  Pensado en México, creado desde distintos puntos del mundo; para todo el mundo.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={9}>
            <Grid
              container
              spacing={2}
              component="ul"
              className={classes.menu}
            >
              {mainMenu.map(item => (
                <Grid
                  key={item.id}
                  item
                  component="li"
                  className={classes.menuItem}
                >
                  <Typography
                    className={classes.menuItemTitle}
                    paragraph
                    component={Link}
                    href={item.url}
                    color="inherit"
                  >
                    {item.title}
                  </Typography>
                  {item.items && item.items.map(subItem0 => (
                    <Typography
                      key={subItem0.id}
                      variant="body2"
                      component="div"
                    >
                      {subItem0.title}
                    </Typography>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item md={12}>
            <Typography
              className={classes.textWhite}
              variant="h5"
            >
              Vehículos para venta, compra, financiamiento, renta, leasing o consignación
            </Typography>
            {makers.map((maker, i) => (
              <Typography
                key={maker}
                className={clsx(classes.textWhite, classes.makers)}
                variant="caption"
              >
                {i !== 0 && ' | '}{maker.toLowerCase()}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item md={6}>
            <Typography
              className={clsx(classes.textWhite, classes.copyRight)}
              variant="body1"
            >
              &copy; ClikAuto {new Date().getFullYear()} Todos los derechos reservados
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Grid
              className={classes.menu}
              container
              component="ul"
              spacing={2}
              justify="flex-end"
            >
              {footerMenu.map(item => (
                <Grid
                  key={item.title}
                  item
                  component="li"
                  className={classes.menuItem}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    component={Link}
                    href={item.url}
                    color="inherit"
                  >
                    {item.title}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default memo(withStyles(styles)(Footer))
