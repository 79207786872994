const styles = (theme) => ({
  title: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontSize: '30px!important',
    lineHeight: '30px!important',
    fontWeight: ' 700!important',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px!important',
      lineHeight: '22px!important',
      fontWeight: ' 700!important',
      textAlign: 'left',
    },
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: '18px!important',
    marginBottom: theme.spacing(2),
    fontWeight: ' 700!important',
  },

  tabs: {
    margin: theme.spacing(3, 0),
    '& .MuiButtonBase-root': {
      backgroundColor: theme.palette.background.tile,
      borderRadius: theme.shape.borderRadius * 7,
      minWidth: 150,
    },
    '& .MuiButtonBase-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      zIndex: 100,
    },

    [theme.breakpoints.up('sm')]: {
      '& .MuiButtonBase-root': {
        minWidth: 330,
        margin: theme.spacing(0, -3),
      },
    },
  },

  helpLink: {
    margin: '0 auto',
    display: 'block',
    width: 'fit-content',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },

  form: {
    backgroundColor: theme.palette.background.primary,
    maxWidth: 600,
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(1),
    // },
  },

  field: {
    marginBottom: theme.spacing(2),
    width: '100%',
    fontWeight: 'regular!important',
    // '.MuiTextField-root': {
    //   '& .MuiFormLabel-root': {
    //     fontWeight: 'regular!important',
    //     fontSize: 12,
    //   },
    // },
  },

  address: {
    marginTop: theme.spacing(2),
    fontWeight: '500',
  },

  button: {
    display: 'block',
    margin: '16px auto',
    width: '65%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  dialog: {
    padding: theme.spacing(3, 2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
});

export default styles;
