const styles = theme => ({
  section: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 99px)',
      position: 'relative',
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
  },

  section1: {
    paddingTop: '0!important',
    // backgroundImage: 'url(/clikauto/img/grayCircle2x.svg), url(/clikauto/img/purpleCircle2x.svg), url(/clikauto/img/pinkCircle2x.svg)',
    // backgroundSize: '50px, 100px',
    // backgroundPosition: 'left 130px, left bottom -82px, right 50%',
    // backgroundRepeat: 'no-repeat',
    // [theme.breakpoints.only('xs')]: {
    //   background: 'none'
    // }
  },

  section2: {
    // backgroundImage: 'url(/clikauto/img/purpleCircle2x.svg)',
    // backgroundSize: '100px',
    // backgroundPosition: 'left -82px',
    // backgroundRepeat: 'no-repeat',
    // [theme.breakpoints.only('xs')]: {
    //   background: 'none'
    // }
  }
})

export default styles
