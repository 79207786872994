import { ADD_REDIRECT } from '../actions/redirect';

export default function redirect(state = null, action) {
  switch (action.type) {
    case ADD_REDIRECT:
      return action.url;
    default:
      return state;
  }
}
