// const apiVersion = 'v1';
let backendHost;
let frontendHost;
let wordpressHost;
let bpmLandingId;
let bpmServiceUrl;
let appEnv;
const devBackend = 'https://staging-api.clikauto.com';
const devFrontend = 'http://localhost:3000';
const devWP = 'https://staging.clikauto.com';

let subdomain = '';
let apiDomain = 'clikauto.com';
const hostname = window && window.location && window.location.hostname;
const appDomain = hostname === 'localhost'
  ? 'localhost'
  : hostname.split('.').slice(1).join('.');

if (hostname === `app.${appDomain}`) {
  appEnv = 'PRODUCTION';
} else if (hostname === `staging-app.${appDomain}`) {
  appEnv = 'STAGING';
  subdomain = `${appEnv.toLowerCase()}-`;
} else if (hostname === `integration-app.${appDomain}`) {
  appEnv = 'INTEGRATION';
  subdomain = `${appEnv.toLowerCase()}-`;
} else if (hostname === 'localhost') {
  appEnv = 'DEVELOPMENT';
}

const isProd = appEnv === 'PRODUCTION';
const isDev = appEnv === 'DEVELOPMENT';
const isIntegration = appEnv === 'INTEGRATION';
const bpmPrefix = isProd ? '' : 'dev-';

apiDomain = isIntegration ? apiDomain : appDomain;
backendHost = isDev ? devBackend : `https://${subdomain}api.${apiDomain}`;
frontendHost = isDev ? devFrontend : `https://${subdomain}app.${appDomain}`;
wordpressHost = isDev
  ? devWP
  : subdomain === ''
    ? `https://${appDomain}`
    : `https://${subdomain.slice(0, -1)}.${appDomain}`;

bpmLandingId = '530b14a7-68d6-4dd7-844d-3aae3a2e09f3';
bpmServiceUrl = `https://${bpmPrefix}autocom.bpmonline.com/0/ServiceModel/GeneratedObjectWebFormService.svc/SaveWebFormObjectData`;

export const APP_ENV = appEnv;
export const IS_PROD = isProd;
export const IS_DEV = isDev;
export const IS_INTEGRATION = isIntegration;
export const APP_DOMAIN = appDomain;
export const APP_ROOT = frontendHost;
export const API_ROOT = `${backendHost}/api`;
export const WP_ROOT = wordpressHost;
export const BPM_LANDING_ID = bpmLandingId;
export const BPM_SERVICE_URL = bpmServiceUrl;
export const EMAIL_ADDRESS = `hola@${appDomain}`;
export const APP_NAME = (
  appDomain.charAt(0).toUpperCase() + appDomain.slice(1)
).split('.')[0];

export const isClikAuto = APP_DOMAIN === 'clikauto.com';

export const GOOGLE_LOGIN_URL = `${API_ROOT}/auth/google_oauth2_${isClikAuto ? 'clikauto' : 'seminuevos'
}`;
export const FACEBOOK_LOGIN_URL = `${API_ROOT}/auth/facebook_${isClikAuto ? 'clikauto' : 'seminuevos'
}`;
