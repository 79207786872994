const logger = store => next => action => {
  // if(process.env.NODE_ENV === 'development'){
  //   console.group(action.type);
  //   console.log('The action: ', action);
  // }
  const returnValue = next(action);
  // if(process.env.NODE_ENV === 'development'){
  //   console.log('The new Store state: ', store.getState());
  //   console.groupEnd();
  // }
  return returnValue;
};
export default logger;
