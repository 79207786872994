import * as API from "../../lib/api";
import { receiveVehicles, addVehicles } from "./inventory";
import { handleInitialFilters } from "./filters";

export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_LOADING_FILTERS = "UPDATE_LOADING_FILTERS";

export function updateLoading(loading = true) {
  return {
    type: UPDATE_LOADING,
    loading
  };
}

export function updateLoadingFilters(loading = true) {
  return {
    type: UPDATE_LOADING_FILTERS,
    loading
  };
}

export function handleInitialData(params = { sort: "dateInStock:desc" }) {
  delete params["page"];
  return dispatch => {
    dispatch(updateLoading(true));
    dispatch(handleInitialFilters(params));
    API.getVehicles(params)
      .then(data => {
        dispatch(receiveVehicles(data));
      })
      .then(() => dispatch(updateLoading(false)));
  };
}

export const fetchInventory = (filters, sorting="dateInStock:desc") => dispatch => {
  dispatch(updateLoading(true));
  const params = { ...filters, sorting };
  if (params.fuelType === 'hibrido-elect') {
    params.fuelType = 'Híbrido/Eléc.'
  }
  API.getVehicles(params)
    .then(data => {
      dispatch(receiveVehicles(data));
    })
    .then(() => {
      dispatch(updateLoading(false));
    });
};


export function handleGoToPage(params) {
  return dispatch => {
    // dispatch(updateLoading(true));
    API.getVehicles(params).then(data => {
      dispatch(addVehicles(data));
    });
    // .then(() => dispatch(updateLoading(false)));
  };
}

export function handleStopLoading() {
  return dispatch => {
    dispatch(updateLoading(false));
  };
}

export const setCommonValue = (key, value, persist = false) => ({
  type: 'SET_SHARED_VALUE',
  key,
  value,
  persist
})
