import {RELOAD_INVENTORY, SCREEN_POSITION} from '../actions/reloadInventory';

const initialState = {positionY: 0, reloadInventory: false};

export default function reloadInventoryd(state = initialState, action) {
  switch (action.type) {
    case RELOAD_INVENTORY:
      return {...state, reloadInventory: action.reloadInventory};
    case SCREEN_POSITION:
      return {...state, positionY: action.positionY};
    default:
      return state;
  }
}
