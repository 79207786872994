const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    '& > div': {
      display: 'flex',
      flexDirection: 'column'
    }
  },

  activeIndex: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },

  slide: {

  },

  navigation: {
    display: 'flex',
    justifyContent: 'center',
  },

  prev: {

  },

  next: {

  },

  pagination: {
    alignSelf: 'center',
    order: 2
  },

  bullet: {
    margin: theme.spacing(.5),
    width: 5,
    height: 5,
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: theme.palette.grey.A100
  },

  bulletActive: {
    margin: theme.spacing(.5),
    width: 5,
    height: 5,
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: theme.palette.common.black
  }
})

export default styles
