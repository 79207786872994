export const ADD_REDIRECT = 'ADD_REDIRECT';

export function addRedirect(url) {
  return {
    type: ADD_REDIRECT,
    url,
  };
}

export function handleRedirect(url) {
  return (dispatch) => Promise.all([dispatch(addRedirect(url))]);
}
