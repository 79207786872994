import * as API from 'lib/api';
import { updateLoading } from './shared';

export const RECEIVE_VEHICLES = 'RECEIVE_VEHICLES';
export const RECEIVE_SIMILAR = 'RECEIVE_SIMILAR';
export const ADD_VEHICLES = 'ADD_VEHICLES';
export const GET_VEHICLE = 'GET_VEHICLE';

export function receiveVehicles(vehicles) {
  return {
    type: RECEIVE_VEHICLES,
    vehicles,
  };
}
export function addVehicles(vehicles) {
  return {
    type: ADD_VEHICLES,
    vehicles,
  };
}
export function getVehicle(vehicle) {
  return {
    type: GET_VEHICLE,
    vehicle,
  };
}
export function receiveSimilar(vehicles) {
  return {
    type: RECEIVE_SIMILAR,
    vehicles,
  };
}

export function handleGetVehicle(vehicle, token = null) {
  return (dispatch) => {
    dispatch(updateLoading(true));
    return API.getVehicle(vehicle, token)
      .then((data) => {
        return dispatch(getVehicle(data));
      })
      .catch((error) => {
        return error.response;
      })
      .then((res) => {
        dispatch(updateLoading(false));
        return res;
      });
  };
}

export function handleGetSimilar(vehicle) {
  return (dispatch) => {
    API.getVehicles({
      used: true,
      body: vehicle.body,
      min: vehicle.price * (1 - 0.2),
      max: vehicle.price * 1.2,
      transmission: vehicle.cesvi_transmission,
    }).then((data) => {
      dispatch(receiveSimilar(data));
    });
  };
}
