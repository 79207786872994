import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import useTheme from '@material-ui/core/styles/useTheme'
import { FormattedMessage } from 'react-intl'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Swiper from 'new/components/Swiper'
import { toggleSellYourCarForm } from 'new/redux/modules/common'
import styles from './styles'
import { Link } from 'react-router-dom'

const GRID_CONTENT = [
  {
    icon: '/clikauto/img/change-your-car.png',
    labelTextID: 'CHANGE_YOUR_CAR',
    descriptionTextID: 'WE_TAKE_YOUR_CAR_ON_ACCOUNT'
  },
  {
    icon: '/clikauto/img/we-buy-your-car.png',
    labelTextID: 'WE_BUY_YOUR_CAR',
    descriptionTextID: 'WE_MAKE_IMMEDIATE_PURCHASE_PROPOSAL'
  },
  {
    icon: '/clikauto/img/we-sell-your-car.png',
    labelTextID: 'WE_SELL_YOUR_CAR',
    descriptionTextID: 'WE_PREPARE_AND_OFFER_YOUR_CAR_TO_POTENTIAL_CUSTOMERS'
  }
]

const Section2 = (props) => {
  const { classes } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const toggleForm = () => dispatch(toggleSellYourCarForm())

  const swiperParams = {
    slidesPerView: 1,
    autoHeight: true,
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      [theme.breakpoints.values.md]: {
        slidesPerView: 3,
        allowTouchMove: false,
        spaceBetween: theme.spacing(6),
        pagination: false
      },
    }
  }

  return (
    <Box
      id="section2"
      className={classes.root}
    >
      <Container className={classes.titleContainer}>
        <Typography
          className={classes.title}
          variant="h2"
          align="center"
        >
          <FormattedMessage id="MORE_X_YOUR_CAR" />
        </Typography>
      </Container>

      <Container className={classes.subtitleContainer}>
        <Typography
          variant="h3"
          align="center"
        >
          <FormattedMessage id="BEST_OFFER_FOR_ONE_CLICK" />
        </Typography>
      </Container>

      <Container className={classes.swiperContainer}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={10}
          >
            <Swiper
              className={classes.swiper}
              options={swiperParams}
              items={GRID_CONTENT}
              renderItem={item => {
                return (
                  <Box className={classes.item}>
                    <Box className={classes.itemIconContainer}>
                      <img src={item.icon} />
                    </Box>

                    <Typography
                      className={classes.itemTitle}
                      variant="h4"
                      align="center"
                    >
                      <FormattedMessage id={item.labelTextID} />
                    </Typography>

                    <Typography
                      className={classes.itemDescription}
                      variant="body2"
                      align="center"
                    >
                      <FormattedMessage id={item.descriptionTextID} />
                    </Typography>
                  </Box>
                )
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Box
          display="flex"
          justifyContent="center"
        >
          <Button
            id="quote-your-car-btn-section-2"
            className={classes.quoteButton}
            variant="contained"
            component={Link}
            to="/vende-tu-auto/cotiza/paso1"
          >
            <FormattedMessage id="QUOTE_YOUR_CAR" />
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default memo(withStyles(styles)(Section2))
