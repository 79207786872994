import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import throttle from 'lodash/throttle';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SellYourCar from 'new/pages/SellYourCar';
import ScheduleForm from 'new/pages/ScheduleForm';
import CreateLead from 'new/pages/CreateLead';
import PaymentConfirmation from 'new/pages/PaymentConfirmation';
import { IS_PROD } from './lib/api-config';
import { loadState, saveState } from './store/localStorage';
import middleware from './store/middleware';
import reducers from './store/reducers';

if (process.env.NODE_ENV === 'production') {
  const hostname = window && window.location && window.location.hostname;

  let environment = 'production';
  if (/staging/.test(hostname)) {
    environment = 'staging';
  } else if (/integration/.test(hostname)) {
    environment = 'integration';
  }
}

const persistedState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(middleware),
);

store.subscribe(
  throttle(() => {
    saveState({
      links: store.getState().links,
      // filters: store.getState().filters,
      pagination: store.getState().pagination,
      vehicles: store.getState().vehicles,
      vehicle: store.getState().vehicle,
      compare: store.getState().compare,
      dealers: store.getState().dealers,
      loadingFilters: store.getState().loadingFilters,
      user: store.getState().user,
      redirect: store.getState().redirect,
      leads: store.getState().leads,
    });
  }, 1000),
);

if (IS_PROD) {
  TagManager.initialize({ gtmId: 'GTM-T5Q3TK8' });
} else {
  TagManager.initialize({ gtmId: 'GTM-MDV9RFG' });
}

const App = lazy(() => import('./components/App'));
const NewApp = lazy(() => import('new/App'));

ReactDOM.render(
  <>
    <Router>
      <Switch>
        <Route
          path="/me-interesa/vehiculo/:vehicleId"
          render={() => (
            <Suspense fallback={null}>
              <NewApp>
                <CreateLead />
              </NewApp>
            </Suspense>
          )}
        />
        <Route
          path="/payment_redirect/:vehicleId/order/:orderId"
          render={() => (
            <Suspense fallback={null}>
              <NewApp>
                <PaymentConfirmation />
              </NewApp>
            </Suspense>
          )}
        />
        <Route
          path="/vende-tu-auto"
          render={() => (
            <Suspense fallback={null}>
              <NewApp>
                <SellYourCar />
              </NewApp>
            </Suspense>
          )}
        />
        <Route
          path="/agendar/:type/:vehicleId"
          render={() => (
            <Suspense fallback={null}>
              <NewApp>
                <ScheduleForm />
              </NewApp>
            </Suspense>
          )}
        />
        <Route
          path="/"
          render={() => (
            <Suspense fallback={null}>
              <Provider store={store}>
                <App />
              </Provider>
            </Suspense>
          )}
        />
      </Switch>
    </Router>
  </>,
  document.getElementById('root'),
);
