const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      justifyContent: 'flex-end'
    }
  },

  testimonials: {
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.background.secondary,
    flexGrow: 1,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 0),
    }
  },

  testimonialsTitleContainer: {

  },

  testimonialsTitle: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },

  testimonialsListContainer: {
    [theme.breakpoints.down('sm')]: {
      order: 3
    }
  },

  testimonialsSwiper: {
    padding: 0
  },

  listItem: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.main,
      // boxShadow: [[0, 16, 54, 'rgba(26, 90, 174, 0.16)']],
      '& $listItemPrimary, $listItemSecondary, $listItemRatingIconFilled': {
        color:theme.palette.secondary.contrastText
      }
    }
  },

  listItemAvatar: {
    width: 80,
    height: 80,
    marginRight: theme.spacing(3),
    border: '4px solid white'
  },

  listItemPrimary: {

  },

  listItemSecondary: {
    marginBottom: 17
  },

  listItemRatingIconFilled: {

  },

  testimonialsVideoTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4)
    }
  },

  testimonialsVideoContainer: {
    [theme.breakpoints.only('xs')]: {
      order: 2
    }
  },

  testimonialsVideo: {
    width: 300,
    height: 200,
    backgroundColor: 'white',
    marginBottom: theme.spacing(2),
    boxShadow: [
      [0, 16, 54, 'rgba(27, 116, 232, 0.08)']
    ]
  },

  testimonialsVideoDescription: {

  },

  banner: {
    backgroundColor: theme.palette.secondary.light,
    // [theme.breakpoints.up('sm')]: {
    //   padding: theme.spacing(13, 0),
    // }
  },

  bannerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    alignItem: 'center',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 10),
      backgroundImage: 'url(/clikauto/img/circles.svg), url(/clikauto/img/stars.svg)',
      backgroundSize: '114px, 80px',
      backgroundPosition: 'left center, right center',
      backgroundRepeat:'no-repeat',
    }
  },

  bannerText: {
    color: theme.palette.secondary.contrastText,
    flex: 2
  },

  bannerButton: {
    display: 'flex',
    height: 'fit-content',
    marginLeft: 100,
    background: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.background.default
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
})

export default styles
