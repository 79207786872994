import React, { memo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(8, 4)
  },

  title: {
    marginBottom: theme.spacing(4)
  },

  closeButton: {
    minWidth: 'unset'
  }
})

const Faq1 = ({ faq, classes, onClose }) => {
  return (
    <Container className={classes.root}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon /> Cerrar
        </Button>
      </Box>

      <Typography variant="h2" className={classes.title}>
        {faq.question}
      </Typography>

      <Typography className={classes.text}>
        <div dangerouslySetInnerHTML={{__html: faq.answer}} />
      </Typography>
    </Container>
  )
}

export default memo(withStyles(styles)(Faq1))
