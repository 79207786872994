import {
  RECEIVE_FILTERS,
  GET_MODELS
} from '../actions/filters'

export default function filters(state = {}, action){
    switch(action.type){
        case RECEIVE_FILTERS :
            return {...state, ...action.filters }
        case GET_MODELS :
            return {...state, ...action.filters, models: action.models }
        default:
            return state
    }
}
