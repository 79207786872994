import {
  RECEIVE_VEHICLES,
  ADD_VEHICLES,
} from '../actions/inventory'

export default function pagination(state = {}, action){
    switch(action.type){
        case RECEIVE_VEHICLES :
            return {...state, ...action.vehicles.pagination }
        case ADD_VEHICLES :
            return {...state, ...action.vehicles.pagination }
        default:
            return state
    }
}
