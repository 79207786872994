import * as API from 'lib/api';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';
export const ADD_TOKEN = 'ADD_TOKEN';
export const ADD_PAYMENTS = 'ADD_PAYMENTS';

export function addErrors(error) {
  return {
    type: ADD_USER_ERROR,
    error,
  };
}
export function removeError() {
  return {
    type: REMOVE_USER_ERROR,
  };
}
export function addUser(user) {
  return {
    type: ADD_USER,
    user,
  };
}
export function updateUser(user) {
  return {
    type: UPDATE_USER,
    user,
  };
}
export function removeUser(token) {
  return {
    type: REMOVE_USER,
    token,
  };
}
export function addToken(token) {
  return {
    type: ADD_TOKEN,
    token,
  };
}
export function addPayments(payments) {
  return {
    type: ADD_PAYMENTS,
    payments,
  };
}

export function handleCleanErrors() {
  return (dispatch) => {
    dispatch(removeError());
  };
}
export function handleLogin(params) {
  return (dispatch) => {
    dispatch(removeError());
    API.userLogin(params).then((res) => {
      if (res.errors) {
        dispatch(addErrors(res.errors));
      } else {
        dispatch(addToken(res.token));
        dispatch(addUser(res.user));
      }
    });
  };
}

export function userIsConnected(token) {
  return (dispatch) =>
    API.myUser(token).then((user) => {
      if (user.errors) {
        return dispatch(removeUser(token));
      } else {
        return Promise.all([
          dispatch(addUser(user)),
          dispatch(addToken(token)),
        ]);
      }
    });
}

export function handleLoginSocial(social_token) {
  return (dispatch) =>
    API.myUser(social_token)
      .then((user) => dispatch(addUser(user)))
      .then(() => dispatch(addToken(social_token)));
}

export function handleStillConnected(token) {
  return (dispatch) =>
    API.myUser(token).then((user) => {
      if (user.errors) {
        return dispatch(removeUser(token));
      } else {
        return user;
      }
    });
}

export function handleLogout(token) {
  return (dispatch) =>
    API.userLogout(token).then((res) => {
      dispatch(removeUser(res.token));
    });
}
export function handleSignup(params) {
  return (dispatch) => {
    API.userSignUp(params).then((res) => {
      dispatch(addUser(res.user));
    });
  };
}
export function handleMyPayments(token) {
  return (dispatch) => {
    API.myPayments(token).then((res) => {
      dispatch(addPayments(res));
    });
  };
}

export function handleUserUpdate(token, params) {
  return (dispatch) => {
    return API.userUpdate(token, params).then((res) => {
      dispatch(updateUser(res));
    });
  };
}
