import * as API from '../../lib/api'
export const RECEIVE_DEALERS = 'RECEIVE_DEALERS';

export function receiveDealers(dealers) {
  return {
    type: RECEIVE_DEALERS,
    dealers,
  };
}

export function handleReceiveDealers(dealers) {
  return dispatch => {
    API.getDealers()
      .then(data => {
        dispatch(receiveDealers(data));
      })
  };
}
