import { withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NumberFormat from 'react-number-format';
import Alert from '@material-ui/lab/Alert';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as API from 'lib/api';
import { APP_ROOT, IS_PROD } from 'lib/api-config';
import { countryCode } from 'new/constants';
import qs from 'query-string';
import styles from './styles';

const BASE_PATH = IS_PROD
  ? 'https://clikauto.com'
  : 'https://clikauto-git-feature-ti-1457-expediente-compra-clikauto.vercel.app';

const Reserve = ({ classes, vehicle }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const queryString = qs.parse(window.location.search);
  const { interestedLeadCreated } = useSelector((s) => s.common);
  const leads = useSelector((store) => store.common.oldStates.leads);
  const initialName = (queryString && queryString.name) ? queryString.name : (interestedLeadCreated ? interestedLeadCreated.name : '');
  const initialPhone = (queryString && queryString.phone) ? queryString.phone : (interestedLeadCreated ? interestedLeadCreated.phone : '');
  const initialEmail = (queryString && queryString.email) ? queryString.email : '';
  const initialOrder = (queryString && queryString.order) ? queryString.order : '';
  const [bpmLead, setBpmLead] = useState({});

  const form = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required('Ingresa tu nombre correctamente')
          .matches(/([a-zA-ZÀ-ÖØ-öø-ÿ])+$/, 'Ingresa tu nombre'),
        phone: yup
          .string()
          .required('Ingresa tu teléfono')
          .min(10, 'Número a 10 digitos')
          .max(10, 'Número a 10 digitos'),
        email: yup
          .string()
          .email('Ingresa un correo electrónico válido')
          .required('Ingresa tu correo electrónico'),
        promoCode: yup
          .string()
          .uppercase()
          .trim(),
      }),
    ),
    defaultValues: {
      name: initialName,
      phone: initialPhone,
      email: initialEmail,
      promoCode: '',
    },
  });

  const [name, phone, email] = useWatch({
    control: form.control,
    name: ['name', 'phone', 'email'],
  });

  // const bpmForm = useForm()
  // const bpmFormContent = (
  //   <form>
  //     {[
  //       'BpmRef', 'Name', 'MobilePhone', 'Email', 'VvtInteresAvaluo', 'UsrVINStrInt2',
  //       'UsrMarcaStrInt2', 'UsrModeloStrInt2', 'UsrVersionStrInt2', 'UsrColorStrInt2',
  //       'UsrKilometrosIntInt2', 'UsrAnioStrInt2', 'UsrTansLookInt2', 'UsrTransLookInt2',
  //       'VvtVehicleMakeStr', 'VvtVehicleModelStr', 'VvtVehicleVersionStr', 'VvtVehicleVIN',
  //       'VvtVehicleType', 'VvtVehicleYear', 'VvtVehicleTransmision', 'UsrNombreFormulario'
  //     ].map(item => (
  //       <input
  //         key={item}
  //         type="hidden"
  //         id={`bpm-${item}`}
  //         name={`bpm-${item}`}
  //         { ...bpmForm.register(`bpm-${item}`)}
  //       />
  //     ))}
  //   </form>
  // )

  const submitBPMForm = async (data) => {
    const { lead } = await API.postLead({
      origin: window.location.href,
      form_name: 'APARTADO (LEAD)',
      content: JSON.stringify({
        ...data,
        vehicleId: vehicle?.id,
        vehicleVin: vehicle?.vin,
        vehicleYear: vehicle?.year,
        vehicleMaker: vehicle?.maker,
        vehicleModel: vehicle?.model,
        vehicleVersion: vehicle?.trim,
        dealerId: vehicle?.dealerId,
        orderId: initialOrder,
        branch_id: vehicle?.branch?.branchId,
        ...leads,
      }),
    });
    setBpmLead(lead);
  };

  const onSubmit = (data) => {
    // const { name, phone, email } = data;
    data.phone = `${countryCode}${phone}`;
    setSubmitting(true);
    submitBPMForm(data);
  };

  useEffect(() => {
    if (bpmLead?.id) {
      const urlRedirect = initialOrder
        ? `${BASE_PATH}/me-interesa/vehiculo/${vehicle?.year}-${`${vehicle?.maker}`.toLowerCase()}-${`${vehicle?.model}`.toLowerCase()}-${vehicle?.state}/${vehicle?.id}/confirmacion?orderId=${initialOrder}`
        : `${APP_ROOT}/payment_redirect/${vehicle.id}`;

      const paramsPayment = {
        name,
        phone,
        email,
        type: 'Vehicle',
        id: vehicle.id,
        service: 'OPENPAY',
        redirect_url: urlRedirect,
      };
      setError(false);
      API.postPayment(null, paramsPayment, bpmLead?.id, initialOrder)
        .then((res) => {
          if (res.status !== 404) {
            window.location.href = res.payment_method ? res.payment_method.url : '';
          } else {
            setError(true);
          }
        }).catch((error) => {
          console.log('error', error);
        });
    }
  }, [bpmLead]);

  return (
    <Container maxWidth="lg">
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Typography
          variant="h2"
          className={classes.title}
          align="center"
          gutterBottom
        >

          Sólo necesitas tus datos y un pago de $5,000 pesos
        </Typography>
        <Alert icon={false} variant="outlined" className={classes.disclaimer}>
          Usa tu tarjeta digital para hacer tu pago seguro. Si utilizas tu tarjeta física, es probable que tu banco no procese tu pago.
        </Alert>
        <Box className={classes.wrapper}>
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                className={classes.field}
                label="Nombre"
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error && fieldState.error.message}
                InputProps={{
                  className: fieldState.isTouched ? 'touched' : '',
                }}
              />
            )}
          />

          <Controller
            name="phone"
            control={form.control}
            render={({ field, fieldState }) => (
              <NumberFormat
                customInput={TextField}
                {...field}
                className={classes.field}
                isAllowed={({ value }) => value.length <= 10}
                label="Teléfono"
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error && fieldState.error.message}
                InputProps={{
                  className: fieldState.isTouched ? 'touched' : '',
                  startAdornment: (
                    <InputAdornment>{countryCode}</InputAdornment>
                  ),
                }}
                type="tel"
              />
            )}
          />

          <Controller
            name="email"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                className={classes.field}
                label="Correo electrónico"
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error && fieldState.error.message}
                InputProps={{
                  className: fieldState.isTouched ? 'touched' : '',
                }}
                type="email"
              />
            )}
          />

          {/* <Controller
            name="promoCode"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                className={classes.field}
                label="Código de referido"
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error && fieldState.error.message}
                value={field.value.toUpperCase()}
                InputProps={{
                  className: fieldState.isTouched ? 'touched' : '',
                }}
              />
            )}
          /> */}
        </Box>

        <Box className={classes.openPayBox}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={5} sm={5} className={classes.openPayLogoContainer}>
              <img src="/clikauto/img/openPay.png" alt="Open Pay Logo" />
            </Grid>

            <Grid item xs={7} sm={7}>
              <Typography variant="body2" gutterBottom>
                Realiza tu apartado con tarjeta de débito o crédito de forma
                segura.
              </Typography>

              <Hidden xsDown>
                <Typography variant="caption">
                  Tu pago es completamente seguro ya que utilizamos los
                  servicios de la plataforma autocom y openpay.
                </Typography>
              </Hidden>
            </Grid>
          </Grid>

          <Hidden smUp>
            <Box p={2}>
              <Typography variant="caption">
                Tu pago es completamente seguro ya que utilizamos los servicios
                de la plataforma autocom y openpay.
              </Typography>
            </Box>
          </Hidden>
        </Box>

        <Box p={2}>
          <Typography variant="body2" align="center">
            Si no lo compras, no te preocupes… ¡Te regresamos tu apartado por
            completo, que no te lo ganen!
          </Typography>
        </Box>

        {error && (
          <Box my={2}>
            <Alert severity="error">Lo sentimos hubo un error. Por favor, inténtalo más tarde.</Alert>
          </Box>
        )}

        <Button
          className={classes.button}
          color="primary"
          type="submit"
          variant="contained"
          disabled={submitting || !name || !phone || !email}
          id="btn_pagar_apartado"
        >
          Realiza tu pago
        </Button>
      </form>

    </Container>
  );
};

export default withStyles(styles)(Reserve);
