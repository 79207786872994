import React, { memo, useEffect, useState } from 'react'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'
import IconButton from '@material-ui/core/IconButton'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Box from '@material-ui/core/Box'
import styles from './styles'

const Swiper = (props) => {
  const {
    items = [],
    renderItem = item => 'item',
    prevEl: prevElProp,
    nextEl: nextElProp,
    showActiveIndex = false,
    showNavigation = false,
    showPagination = false,
    onActiveSlideChange = null,
    options: optionsProp,
    className: classNameProp,
    component: Component = Box,
    classes,
    ...other
  } = props

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  useEffect(() => {
    if (typeof onActiveSlideChange === "function") {
      onActiveSlideChange(activeSlideIndex)
    }
  }, [activeSlideIndex])

  const options = {
    slidesPerView: 1,
    pagination: showPagination && {
      modifierClass: `${classes.pagination} `,
      clickable: true,
      type: 'bullets',
      bulletElement: 'span',
      bulletClass: classes.bullet,
      bulletActiveClass: classes.bulletActive
    },
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    onBeforeInit: swiper => {
      swiper.params.navigation.prevEl = navigationPrevRef.current
      swiper.params.navigation.nextEl = navigationNextRef.current
    },
    onActiveIndexChange: swiper => setActiveSlideIndex(swiper.activeIndex),
    ... optionsProp
  }

  const prevEl = prevElProp || (
    <IconButton
      color="inherit"
      size="small"
      className={classes.prev}
    >
      <ArrowLeftIcon />
    </IconButton>
  )

  const nextEl = nextElProp || (
    <IconButton
      color="inherit"
      size="small"
      className={classes.next}
    >
      <ArrowRightIcon />
    </IconButton>
  )

  const className = clsx(classNameProp, classes.root)

  return (
    <Component
      className={className}
      { ...other }
    >
      { showActiveIndex && (
        <div className={classes.activeIndex}>
          <span>{activeSlideIndex + 1}</span>
        </div>
      )}

      <ReactSwiper { ...options }>
        {items.map((item, i) => (
          <SwiperSlide key={i}>
            {renderItem(item)}
          </SwiperSlide>
        ))}

        {showNavigation && (
          <div className={classes.navigation}>
            <div ref={navigationPrevRef}>
              {prevEl}
            </div>
            <div ref={navigationNextRef}>
              {nextEl}
            </div>
          </div>
        )}
      </ReactSwiper>
    </Component>
  )
}

export default memo(withStyles(styles)(Swiper))
