import {
  RECEIVE_DEALERS,
} from '../actions/dealers'

export default function dealers(state = [], action){
    switch(action.type){
        case RECEIVE_DEALERS :
            return [...action.dealers]
        default:
            return state
    }
}
