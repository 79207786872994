import {RECEIVE_SIMILAR} from 'store/actions/inventory';

export default function inventory(state = [], action) {
  switch (action.type) {
    case RECEIVE_SIMILAR:
      return [...action.vehicles.inventory];
    default:
      return state;
  }
}
