import React, { memo, useContext, useEffect, useRef } from 'react'
import format from "date-fns/format"
import { useForm, useWatch, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FormattedMessage, useIntl } from 'react-intl'
import { MuiPickersContext } from '@material-ui/pickers'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MenuItem  from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from 'new/components/core/Typography'
import DatePicker from 'new/components/core/DatePicker'
import useAxios from 'new/hooks/useAxios'
import styles from './styles'


const HOURLY_SLOTS = ['00','30']
const OFFICE_OPEN = 9
const OFFICE_CLOSE = 17
const OFFICE_CLOSE_WEEKEND = 12
const MIN_HOURS_TO_SCHEDULE = 2
const DEFAULT_MAX_DAYS_TO_SCHEDULE = 4

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const sameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate()

const getTimeSlots = (from, to) => {
  return Array(((to - from) * HOURLY_SLOTS.length) + 1)
    .fill(0)
    .map((_, i) =>
      `${(Math.floor(i / HOURLY_SLOTS.length)) + from}:${HOURLY_SLOTS[i % 2]}`)
}

const now = new Date()
const ScheduleForm = ({ classes, data, onSubmit, minDate = now, maxDays = DEFAULT_MAX_DAYS_TO_SCHEDULE }) => {
  const {locale} = useContext(MuiPickersContext)
  const {formatMessage: f} = useIntl()
  const maxDate = addDays(minDate, maxDays)

const formatHour = (hour) => {
  let parts = hour.split(':')
  let am = true
  const h = parseInt(parts[0])

  if (h > 11) {
    am = false
  }

  return `${h == 12 ? h : h % 12}:${parts[1]} ${am ? 'AM' : 'PM'}`
}

  const form = useForm({
    resolver: yupResolver(yup.object().shape({
      name: yup.string().required('Nombre es requerido'),
      day: yup.date().required('Selecciona un día'),
      time: yup.string().required('Selecciona una hora'),
      city: yup.string(),
      whatsapp: yup.string()
        .required('Ingresa tu whatsapp')
        .min(10, 'Teléfono a 10 digitos')
        .max(10, 'Teléfono a 10 digitos')
    })),
    defaultValues: {
      name: '',
      day: null,
      time: '',
      city: data.city,
      whatsapp: '',
    }
  })

  const formData = useWatch({
    control: form.control,
    name: ['name', 'day', 'time', 'office', 'whatsapp'],
  })

  const [{data: offices, loading: loadingOffices, error: errorOffices}] = useAxios('inspection_locations')


  const getTimes = () => {
    let currentHour = now.getHours()

    if (!formData.day) {
      return []
    }

    if (sameDay(formData.day, now)) {
      const closeTime = formData.day.getDay() === 6 ? OFFICE_CLOSE_WEEKEND : OFFICE_CLOSE
      if (currentHour + MIN_HOURS_TO_SCHEDULE > closeTime) {
        return []
      } else  if(currentHour + MIN_HOURS_TO_SCHEDULE < OFFICE_OPEN) {
        return formData.day.getDay() === 6 ?
          getTimeSlots(OFFICE_OPEN, OFFICE_CLOSE_WEEKEND) :
          getTimeSlots(OFFICE_OPEN, OFFICE_CLOSE)
      } else {
        return getTimeSlots(currentHour + MIN_HOURS_TO_SCHEDULE, closeTime)
      }

    } else {
      console.log('other day')
      return formData.day.getDay() === 6 ?
        getTimeSlots(OFFICE_OPEN, OFFICE_CLOSE_WEEKEND) :
        getTimeSlots(OFFICE_OPEN, OFFICE_CLOSE)
    }
  }

  function disableWeekends(date) {
    return date.getDay() === 0
  }

  return (
    <Box>
      <Typography
        variant="h2"
        className={classes.title}
      >
        {data.title}
      </Typography>

      <Typography
        variant="h5"
        className={classes.subtitle}
      >
        {data.subtitle}
      </Typography>

      <Box className={classes.formContainer}>
        <Typography
          variant="h4"
          className={classes.formTitle}
        >
          {data.formTitle}
        </Typography>

        <Grid
          container
          spacing={4}
        >
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
          >
            <Controller
              name="name"
              control={form.control}
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  className={classes.field}
                  value={field.value}
                  onChange={field.onChange}
                  label={f({ id: 'SCHEDULE_FORM_LABEL_NAME' })}
                  placeholder={f({ id: 'SCHEDULE_FORM_PLACEHOLDER_NAME' })}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error && fieldState.error.message}
                  InputProps={{ className: fieldState.isTouched ? 'touched' : '' }}
                />
              )}
            />

            <Controller
              name="whatsapp"
              control={form.control}
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  type="tel"
                  className={classes.field}
                  value={field.value}
                  onChange={field.onChange}
                  label={f({ id: 'SCHEDULE_FORM_LABEL_WHATSAPP' })}
                  placeholder={f({ id: 'SCHEDULE_FORM_PLACEHOLDER_WHATSAPP' })}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error && fieldState.error.message}
                  InputProps={{ className: fieldState.isTouched ? 'touched' : '' }}
                />
              )}
            />

            <Controller
              name="day"
              control={form.control}
              render={({field, fieldState}) => (
                <DatePicker
                  {...field}
                  className={classes.field}
                  label={f({ id: 'SCHEDULE_FORM_LABEL_DAY' })}
                  emptyLabel={f({ id: 'SCHEDULE_FORM_PLACEHOLDER_DAY' })}
                  fullWidth
                  format="dd MMMM"
                  value={field.value}
                  onChange={field.onChange}
                  disableToolbar
                  variant="inline"
                  autoOk
                  InputProps={{
                    endAdornment: <KeyboardArrowDownIcon />,
                    className: fieldState.isTouched ? 'touched' : ''
                  }}
                  disablePast
                  shouldDisableDate={disableWeekends}
                  error={fieldState.invalid}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              )}
            />

            <Controller
              name="time"
              control={form.control}
              render={({field, fieldState}) => (
                <TextField
                  className={classes.field}
                  select
                  value={field.value}
                  onChange={field.onChange}
                  label={f({ id: 'SCHEDULE_FORM_LABEL_TIME' })}
                  fullWidth
                  SelectProps={{
                    displayEmpty: true
                  }}
                  error={fieldState.invalid}
                  // helperText={form.errors.time && form.errors.time.message}
                  InputProps={{ className: fieldState.isTouched ? 'touched' : '' }}
                >
                  <MenuItem value="">
                    <FormattedMessage id="SCHEDULE_FORM_PLACEHOLDER_TIME" />
                  </MenuItem>

                  {getTimes().map((option) => (
                    <MenuItem key={option} value={option.toString()}>
                      {formatHour(option)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {data.type === 'visita' && (
              <Controller
                name="city"
                control={form.control}
                render={({field, fieldState}) => (
                  <TextField
                    className={classes.field}
                    value={field.value}
                    onChange={field.onChange}
                    label={f({ id: 'SCHEDULE_FORM_LABEL_OFFICE' })}
                    fullWidth
                    error={fieldState.invalid}
                    InputProps={{ className: fieldState.isTouched ? 'touched' : '' }}
                    disabled
                  />
                )}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            container
          >
            <Box className={classes.summary}>
              {!formData.name && !formData.day && !formData.time && !formData.office && !formData.whatsapp && (
                <Typography variant="h4">
                  {data.summaryTitle}
                </Typography>
              )}

              {formData.name && (
                <Box pb={2}>
                  <Typography variant="body2">
                    <FormattedMessage id="SCHEDULE_FORM_LABEL_NAME" />
                  </Typography>
                  <Typography variant="h1">
                    {formData.name}
                  </Typography>
                </Box>
              )}

              {formData.whatsapp && (
                <Box pb={2}>
                  <Typography variant="body2">
                    <FormattedMessage id="SCHEDULE_FORM_LABEL_WHATSAPP" />
                  </Typography>
                  <Typography variant="h1">
                    {formData.whatsapp}
                  </Typography>
                </Box>
              )}

              {formData.day && (
                <Box pb={2}>
                  <Typography variant="body2">
                    <FormattedMessage id="SCHEDULE_FORM_LABEL_DAY" />
                  </Typography>
                  <Typography variant="h1">
                    {format(formData.day, "dd MMMM", { locale })}
                  </Typography>
                </Box>
              )}

              {formData.time && (
                <Box pb={2}>
                  <Typography variant="body2">
                    <FormattedMessage id="SCHEDULE_FORM_LABEL_TIME" />
                  </Typography>
                  <Typography variant="h1">
                    {formData.time}
                  </Typography>
                </Box>
              )}

              {data.type === 'visita' && formData.city && (
                <Box pb={2}>
                  <Typography variant="body2">
                    <FormattedMessage id="SCHEDULE_FORM_LABEL_OFFICE" />
                  </Typography>
                  <Typography variant="h1">
                    {formData.city}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={2}
        justify="center"
        className={classes.bottomButtons}
      >
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
        >
          <Button
            component="a"
            id={`schedule-form-cancel-button`}
            className={classes.button}
            variant="contained"
            href={data.backUrl}
            fullWidth
          >
            <FormattedMessage id="SCHEDULE_FORM_CANCEL_BUTTON" />
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={3}
        >
          <Button
            id={`schedule-form-submit-button`}
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
            fullWidth
          >
            <FormattedMessage
              id="SCHEDULE_FORM_SUBMIT_BUTTON"
              values={{
                action: data.type
              }}
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(withStyles(styles)(ScheduleForm))
