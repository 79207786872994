import {
    UPDATE_LOADING_FILTERS,
} from '../actions/shared'

export default function loadingFilters(state = true, action){
    switch(action.type){
        case UPDATE_LOADING_FILTERS :
            return action.loading
        default:
            return state
    }
}
