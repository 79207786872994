import React, { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import useTheme from '@material-ui/core/styles/useTheme'
import { FormattedMessage } from 'react-intl'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import usePageData from 'new/hooks/usePageData'
import Swiper from 'new/components/Swiper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Rating from 'new/components/core/Rating'
import { toggleSellYourCarForm } from 'new/redux/modules/common'
import styles from './styles'
import { Link } from 'react-router-dom'


const Section3 = (props) => {
  const { classes } = props
  const { testimonials } = usePageData()
  const theme = useTheme()
  const [selectedItem, setSelectedItem] = useState(testimonials[0])
  const dispatch = useDispatch()
  const toggleForm = () => dispatch(toggleSellYourCarForm())

  const titleContent = (
    <Typography
      className={classes.testimonialsTitle}
      variant="h2"
    >
      <FormattedMessage id="ALL_DAYS_WE_BUY_SELL_OR_TRADE_CARS" />
    </Typography>
  )

  const sliderOptions = {
    breakpoints: {
      [theme.breakpoints.values.sm]: {
        slidesPerView: 3,
        direction: 'vertical',
      }
    }
  }

  const testimonialsContent = (
    <Swiper
      className={classes.testimonialsSwiper}
      options={sliderOptions}
      items={testimonials}
      showPagination
      onActiveSlideChange={(index) => setSelectedItem(testimonials[index])}
      renderItem={item => (
        <ListItem
          key={item.id}
          alignItems="flex-start"
          className={classes.listItem}
          onClick={() => setSelectedItem(item)}
        >
          <ListItemAvatar>
            <Avatar
              alt={item.author.name}
              src={item.author.avatar}
              className={classes.listItemAvatar}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                variant="h4"
                color="primary"
                className={classes.listItemPrimary}
              >
                {item.author.name}
              </Typography>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  display="block"
                  className={classes.listItemSecondary}
                >
                  {item.text}
                </Typography>
                <Rating
                  value={item.rating}
                  readOnly
                  classes={{ iconFilled: classes.listItemRatingIconFilled }}
                />
              </>
            }
          />
        </ListItem>
      )}
    />
  )

  const videoContent = (
    <React.Fragment>
      <Typography
        variant="h3"
        className={classes.testimonialsVideoTitle}
      >
        <FormattedMessage id="COMPANY_IS_BEST_OPTION" />
      </Typography>

      <Box className={classes.testimonialsVideo}>
        {/*<iframe*/}
        {/*  width="100%" height="100%"*/}
        {/*  src={selectedItem.videoUrl} title="YouTube video player"*/}
        {/*  frameBorder="0"*/}
        {/*  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
        {/*  allowFullScreen>*/}
        {/*</iframe>*/}
      </Box>

      <Typography
        variant="body1"
        className={classes.testimonialsVideoDescription}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a egestas augue. Sed placerat fermentum metus sit amet finibus. Etiam scelerisque aliquam tempus.
      </Typography>
    </React.Fragment>
  )

  return (
    <Box
      className={classes.root}
    >
      {/*<Box className={classes.testimonials}>*/}
      {/*  <Container>*/}
      {/*    <Grid*/}
      {/*      container*/}
      {/*      direction="row"*/}
      {/*      justify="center"*/}
      {/*      alignItems="center"*/}
      {/*    >*/}
      {/*      <Grid*/}
      {/*        item*/}
      {/*        xs={12}*/}
      {/*        sm={12}*/}
      {/*        md={10}*/}
      {/*        container*/}
      {/*        spacing={4}*/}
      {/*      >*/}
      {/*        <Grid*/}
      {/*          item*/}
      {/*          xs={12}*/}
      {/*          className={classes.testimonialsTitleContainer}*/}
      {/*        >*/}
      {/*          {titleContent}*/}
      {/*        </Grid>*/}

      {/*        <Grid*/}
      {/*          item*/}
      {/*          xs={12}*/}
      {/*          sm={12}*/}
      {/*          md={6}*/}
      {/*          className={classes.testimonialsListContainer}*/}
      {/*        >*/}
      {/*          {testimonialsContent}*/}
      {/*        </Grid>*/}

      {/*        <Grid*/}
      {/*          item*/}
      {/*          xs={12}*/}
      {/*          sm={12}*/}
      {/*          md={6}*/}
      {/*          className={classes.testimonialsVideoContainer}*/}
      {/*        >*/}
      {/*          {videoContent}*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </Container>*/}
      {/*</Box>*/}

      <Box className={classes.banner}>
        <Container
          className={classes.bannerContainer}
          component={Box}
        >
          <Typography
            className={classes.bannerText}
            variant="h3"
            align="center"
          >
            <FormattedMessage id="SELL_YOUR_CAR_IN_5_MINUTES" />
          </Typography>

          <Button
            className={classes.bannerButton}
            component={Link}
            to="/vende-tu-auto/cotiza/paso1"
          >
            <FormattedMessage id="QUOTE_YOUR_CAR" />
          </Button>
        </Container>
      </Box>
    </Box>
  )
}

export default memo(withStyles(styles)(Section3))
