import { withStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MuiPickersContext } from '@material-ui/pickers';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import NumberFormat from 'react-number-format';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import format from 'date-fns/format';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { getVehicleLink } from 'lib/utils';
import styles from './styles';

const totalSeconds = 30 * 60 - 1;
const Home = ({ classes, vehicle }) => {
  const [timer, setTimer] = useState(0);
  const history = useHistory();
  const { url } = useRouteMatch();
  const { locale } = useContext(MuiPickersContext);
  const { interestedLeadCreated, reservation } = useSelector((s) => s.common);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = () => {
    const auxDate = new Date(0);
    auxDate.setSeconds(totalSeconds - timer);
    return `${auxDate.toISOString().substr(14, 5)} min`;
  };

  const title = (
    <Typography
      variant="h2"
      className={classes.title}
    >
      { reservation
        ? `¡${reservation.customer_hash.name}, está todo listo para que conozcas y estrenes pronto el auto de tus sueños!`
        : interestedLeadCreated ? (
          <FormattedMessage id="APART_NOW" values={{ name: interestedLeadCreated.name, type: interestedLeadCreated.type }} />
        ) : (
          <FormattedMessage id="WHAT_DO_YOU_WANT_TO_DO_NOW" />
        )}
    </Typography>
  );

  return (
    <Container maxWidth="md">
      <Hidden smDown>{title}</Hidden>

      <Box className={classes.wrapper}>
        <Grid
          container
          spacing={2}
        >
          <Hidden mdUp>
            <Grid item xs={12} className={classes.titleContainer}>
              {title}
            </Grid>
          </Hidden>

          <Grid
            item
            xs={12}
            sm={6}
            className={classes.vehicleDetails}
          >
            <Box className={classes.box1}>
              <Typography
                variant="h3"
              >
                {`${vehicle.year} ${vehicle.maker} ${vehicle.model}`}
              </Typography>

              <Typography
                variant="h4"
              >
                <NumberFormat
                  displayType="text"
                  value={vehicle.price}
                  prefix="$"
                  decimalScale={0}
                  thousandSeparator
                />
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            container
            className={classes.buttonOptions}
            spacing={2}
          >
            {reservation ? (
              <>
                <Grid
                  item
                  md={6}
                  xs={6}
                  className={classes.amount}
                >
                  <Box className={classes.bgWhite}>
                    <Typography variant="h5">Monto:</Typography>
                    <Typography variant="h5" className={classes.blueText}>
                      <NumberFormat
                        displayType="text"
                        value={reservation.amount}
                        thousandSeparator
                        decimalScale={0}
                        fixedDecimalScale
                        prefix="$"
                      />
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={6}
                  className={classes.orderId}
                >
                  <Box className={classes.bgWhite}>
                    <Typography variant="h5">Reservación:</Typography>
                    <Typography variant="h5" className={classes.blueText}>
                      {reservation.order_number}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={12}
                  className={classes.reserveExpiration}
                >
                  <Box px={1}>
                    <Typography variant="body2">
                      Te recordamos que tu apartado es 100% reembolsable, en caso de que no realizar la compra del auto. El apartado tiene una vigencia de 72hrs.
                    </Typography>
                  </Box>
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
              >
                <ButtonBase
                  className={clsx(classes.box2, { [classes.disabled]: Boolean(reservation) })}
                  component="div"
                  onClick={() => history.push(`${url}/apartado${window.location.search}`)}
                  disabled={Boolean(reservation)}
                >
                  <Box display="flex">
                    <Typography variant="h4" align="left" gutterBottom className={classes.reserveNowTitle}>
                      {reservation ? '¡Apartamos para ti este auto por 72hrs!' : '¡Apártalo antes de que te lo ganen!'}

                    </Typography>
                    <IconButton size="small" className={classes.rightIconButton}>
                      {reservation ? <DoneIcon /> : <ChevronRightIcon />}
                    </IconButton>
                  </Box>

                  {!reservation && (
                    <Typography variant="caption">
                      <span style={{ color: 'black' }}>Apártalo por solo $2,000</span>
                      {' '}
                      y te lo llevamos hasta tu casa para que lo conozcas de defensa a defensa y te enamores de él.
                    </Typography>
                  )}
                </ButtonBase>
              </Grid>
            )}

            <Grid
              item
              xs={12}
            >
              {interestedLeadCreated ? (
                <ButtonBase
                  className={classes.box3}
                  component="div"
                >
                  <Typography variant="h4" align="left" gutterBottom>
                    Conócelo el
                    {' '}
                    {' '}
                    {format(new Date(interestedLeadCreated.day), 'dd MMMM yyyy', { locale })}
                    {' | '}
                    {interestedLeadCreated.time}
                  </Typography>

                  <Typography variant="caption" align="left" gutterBottom>
                    {interestedLeadCreated.type === 'Videollamada'
                      ? 'Te llamaremos por WhatsApp para que lo conozcas, ten a la mano tu celular.'
                      : `Lo conocerás en la sucursal, que se encuentra en ${vehicle.seller.address}`}
                  </Typography>
                </ButtonBase>
              ) : (
                <ButtonBase
                  className={classes.box3}
                  component="div"
                  onClick={() => history.push(`${url}/conocelo/paso1${window.location.search}`)}
                >
                  <Box display="flex">
                    <Typography variant="h4" align="left" gutterBottom className={classes.kowItTitle}>
                      {vehicle.seller.allowVisit && vehicle.seller.allowVideocall
                        ? 'Conócelo gratis por videollamada o en sucursal'
                        : vehicle.seller.allowVisit ? 'Conócelo gratis en sucursal' : 'Conócelo gratis por videollamada'}
                    </Typography>
                    <IconButton size="small" className={classes.rightIconButton}>
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>

                  <Typography variant="caption" align="left" gutterBottom>
                    Sólo tienes que seleccionar el día y la hora para conocerlo. ¡Recuerda que es completamente GRATIS!
                  </Typography>
                </ButtonBase>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {Boolean(reservation) && Boolean(interestedLeadCreated) && (
        <Button
          className={classes.acceptButton}
          color="primary"
          variant="contained"
          component="a"
          href={getVehicleLink(vehicle)}
        >
          Aceptar
        </Button>
      )}
    </Container>
  );
};

export default withStyles(styles)(Home);
