const styles = (theme) => ({
  title: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },

  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: 18,
    textAlign: 'center'
  },

  formContainer: {
    backgroundColor: theme.palette.background.primary,
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },

  formTitle: {
    paddingBottom: theme.spacing(3),
    fontSize: 20
  },

  summary: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.black,
    flex: 1,
    borderRadius: theme.shape.borderRadius * 2,
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center'
  },

  field: {
    paddingBottom: theme.spacing(4)
  },


  bottomButtons: {

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8)
    }
  },

  button: {
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
      width: '100%'
    },
  }
})

export default styles
